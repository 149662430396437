import React from 'react'
import AppBar from '@mui/material/AppBar'
import { styled } from '@mui/material'

const Footer = () => {
  const CustomizedSlider = styled(AppBar)`
    background: transparent;
    height: 100px;
    align-content: flex-end;
    box-shadow: none;
  `
  return (
    <CustomizedSlider position="static" color="primary" sx={{ top: 'auto', bottom: 0 }}>
    </CustomizedSlider>
  )
}

export default Footer