import { EXPENSES_CATEGORY, MONTHS_SELECT, STATEMENT_TYPE, TRANSACTION_DESCRIPTION } from '../../../utils/Enums'
import { yearObjectsArray } from '../../../utils/methods'
import { styled, Switch } from '@mui/material'
import { usedColor } from '../../../theme/palette'
import React from 'react'

export const selectData = {
  gender: [
    { name: 'Male', value: 'Male' },
    { name: 'Female', value: 'Female' },
  ],
  status: [
    { name: 'married', value: 'married' },
    { name: 'single', value: 'single' },
    { name: 'widow', value: 'widow' },
    { name: 'divorce', value: 'divorce' },
  ],
  familyCount:[
    { name: '1', value: '1' },
    { name: '2', value: '2' },
    { name: '3', value: '3' },
    { name: '4', value: '4' },
    { name: '5', value: '5' },
    { name: '6', value: '6' },
    { name: '7', value: '7' },
    { name: '8', value: '8' },
    { name: '9', value: '9' },
    { name: '10', value: '10' },
  ],
  type: [
    { name: 'CASH', value: 'CASH' },
    { name: 'BANK', value: 'BANK' },
  ],
  statementType: [
    { name: STATEMENT_TYPE.MONTH_FEE, value: STATEMENT_TYPE.MONTH_FEE },
    { name: STATEMENT_TYPE.OTHER_FEE, value: STATEMENT_TYPE.OTHER_FEE },
  ],
  description: [
    { name: TRANSACTION_DESCRIPTION.MONTH_FEE, value: TRANSACTION_DESCRIPTION.MONTH_FEE },
    { name: TRANSACTION_DESCRIPTION.DONATION, value: TRANSACTION_DESCRIPTION.DONATION },
  ],
  category: Object.keys(EXPENSES_CATEGORY).map(key => ({
    name: EXPENSES_CATEGORY[key],
    value: EXPENSES_CATEGORY[key],
  })),

  month: Object.keys(MONTHS_SELECT).map(key => ({
    name: MONTHS_SELECT[key],
    value: MONTHS_SELECT[key],
  })),
  year: yearObjectsArray,
}

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: usedColor.common['1'],
      '& + .MuiSwitch-track': {
        backgroundColor: usedColor.success.dark,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: usedColor.success.main,
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: usedColor.error.main
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: usedColor.error.dark,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))