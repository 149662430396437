import React from 'react'
import { Grid } from '@mui/material'

const testGrid = (props) => {



  const { children, sx = { marginTop: '0rem', marginBottom: '0rem', width: '100%'  } } = props


  return(
    <>
      <Grid sx={sx}>
        {children}
      </Grid>
    </>)
}

export default testGrid
