import React, { useMemo } from 'react'
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material'
import { palette, usedColor } from './palette'
import { components } from './components'
import { useAppSetting } from '../components/providers/AppSettingProvider'
import { arSA, fiFI } from '@mui/material/locale'
import BackGroundWrapper from './BackGroundWrapper'

const ThemeWrapper = ({ children }) => {
  const { data } = useAppSetting()


  const lang = useMemo(() => {
    return {
      ['ar']: arSA,
      ['fi']: fiFI
    }
  }, [])
  document.getElementById('root').style.direction = data?.direction ?? 'rlt'

  const themeWithLocale = useMemo(
    () => {
      const theme = {
        typography: {
          fontFamily: [
            'Almarai',
            'sans-serif'
          ].join(','),
        },
        palette: palette(data?.mode),
        components: components(data?.mode)
      }
      theme.typography.subtitle1 = {
        fontSize: '1.2rem',
        '@media (min-width:600px)': {
          fontSize: '1.5rem',
          margin: '0px',
        },
      }

      theme.typography.h4 = {
        fontSize: '1.5rem',
        '@media (min-width:600px)': {
          fontSize: '2rem',
          marginBottom: '20px',
        },
      }

      theme.typography.h6 = {
        fontSize: '0.8rem',
        color: usedColor.primary.main,
        '@media (min-width:600px)': {
          fontSize: '1rem',
          margin: '0px',
        },
      }


      theme.typography.h5 = {
        fontSize: '1.1rem',
        '@media (min-width:600px)': {
          fontSize: '1.2rem',
          margin: '0px',
        },
      }
      return createTheme(theme, lang[data?.language], data?.direction)
    },
    [data, lang]
  )

  return (
    <>
      <CssBaseline />
      <BackGroundWrapper image={null}>
        <ThemeProvider theme={themeWithLocale}>{children}</ThemeProvider>
        {/* <Typography sx={{ bottom: 0 }}>{process.env.NODE_ENV}</Typography>*/}
      </BackGroundWrapper>

    </>

  )
}

export default ThemeWrapper
