import { useLocation, useNavigate } from 'react-router-dom'
import { useMemo } from 'react'

export function useRouter() {
  const navigate = useNavigate()
  const location = useLocation()

  return  useMemo(
    () => ({
      back: () => navigate(-1),
      forward: () => navigate(1),
      reload: () => window.location.reload(),
      push: (href) => navigate(href),
      pushWithData: (href, data) => navigate(href, { state: data }),
      replace: (href) => navigate(href, { replace: true }),
      refresh: () => navigate(location.pathname, { replace: true })

    }),
    [navigate, location.pathname]
  )
}