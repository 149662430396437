import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'

const TypographyApp = ({ variant, str, marginBottom = 1 , varObject = {} }) => {
  const { t } = useTranslation()
  return (typeof str === 'string' ?
    <Typography
      style={{ marginBottom: `${marginBottom}rem`, marginTop: `${marginBottom}rem` }}
      variant={variant}>
      {t(str, varObject)}
    </Typography> : <p></p>
  )
}

export default TypographyApp
