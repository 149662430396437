import React from 'react'
import CustomGrid from '../../../theme/TestGrid'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { InputAdornment, Typography } from '@mui/material'

const LabeledInfoField = ({ id, value, label }) => {
  return (
    <CustomGrid>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }} >
        <TextField
          fullWidth
          disabled={true}
          id={id}
          name={id}
          value={value}
          InputProps={{
            startAdornment: <InputAdornment position="start" sx={{ marginLeft: '0.5rem' }}>
              <Typography variant='h6' sx={{ borderLeft: '1px dashed',  minWidth: '120px' }}>{label}</Typography>
            </InputAdornment>,
          }}
          multiline={true}
        />
      </Box>
    </CustomGrid>
  )
}

export default LabeledInfoField