import React, { useEffect, useState } from 'react'
import CommonTable from '../../components/commons/CommonTable'
import { statement } from '../../services/api/statements'
import { useMessageContext } from '../../components/providers/MessageProvider'
import CustomAppModal from '../../components/commons/customs/CustomAppModal'
import CustomButton from '../../components/commons/customs/CustomButton'
import Forms from '../../components/dataForm/forms/Forms'
import { DATA_FROMS, TABLE_TYPE } from '../../utils/Enums'
import { useTranslation } from 'react-i18next'
import { balances } from '../../services/api/balances'
import { tableColumns } from '../../data/data'
import { tableFormatRowCell } from '../../data/rows_data'

const StatementsTable = () => {
  const { t } = useTranslation()
  const [statements, setStatements] = useState({})
  const [formatted, setFormatted] = useState([])
  const [chosenItem, setChosenItem] = useState(null)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const { setInfoModal, setFormType } = useMessageContext()

  const fetchStatements = async (pageParameter, limitParameter) => {
    try {
      const data =  await statement.getAllStatements(pageParameter, limitParameter)
      setStatements(data)
      const formatted = data?.results.map(s => tableFormatRowCell[TABLE_TYPE.STATEMENT](s, t))
      setFormatted(formatted)
      setPage(data.page)
    } catch (e) {
      console.error(e)
    }

  }
  useEffect(() => {
    fetchStatements(page, rowsPerPage).
      catch(err => console.error(err.message))
    // eslint-disable-next-line
  }, [])

  const handleChosenItem = (chosenStatement) => {
    if (chosenStatement && chosenStatement.isChecked) {
      setInfoModal({
        title: 'errors.statement.isChecked.context',
      })
    } else {
      setChosenItem(chosenStatement)
    }
  }

  const handleChangePage = (event, newPage) => {
    fetchStatements(newPage, rowsPerPage).catch(err => console.error(err.message))
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    console.log('Page has been changed', event)
    setRowsPerPage(event.target.value)
    fetchStatements(page, event.target.value).catch(err => console.error(err.message))
    setPage(0)
  }

  const handleDeleting = async () => {
    try {
      await statement.deleteStatemnt(chosenItem.id).catch(e => console.error(e.message))
      await fetchStatements(page, rowsPerPage)
      setChosenItem(null)
    } catch (e) {
      console.error(e.message)
    }
  }

  const handleRunStatement = async () => {
    try {
      await balances.runStatementBalance(chosenItem.id)
      fetchStatements(page, rowsPerPage).catch(err => console.error(err.message))
    } catch (e) {
      console.error(e.message)
    }
  }

  const modalButtons = [
    <CustomButton text={'statement.modal.charge'} handleClick={() => handleRunStatement()} key={'RunStatement'}/>,
    <CustomButton text={'statement.modal.delete'} handleClick={handleDeleting} key={'delete'}/>
  ]


  const createNewStatement = async (date) => {
    try {
      await statement.createStatement(date).catch(e => console.error(e.message))
      await fetchStatements(page, rowsPerPage)
      setFormType(DATA_FROMS.CLOSED)
    } catch (e) {
      console.error(e.message)
    }
  }
  return (
    <div>
      <CustomAppModal
        chosenItem={chosenItem}
        arg1={`${chosenItem?.fee} - ${chosenItem?.month} - ${chosenItem?.year}`}
        closing={() => setChosenItem(null)}
        modalContent={{ modalTitle: 'statement.modal.title',modalContent: 'statement.modal.content' }}
        buttons={modalButtons}
      />
      <CommonTable
        columns={tableColumns[TABLE_TYPE.STATEMENT]}
        page={page}
        rowsPerPage={rowsPerPage}
        rows={formatted}
        totalRows={statements.totalItems || 0}
        onRowClick={handleChosenItem}
        onPageChange={handleChangePage}
        onRowPerPage={handleChangeRowsPerPage}
      />
      <Forms type={DATA_FROMS.STATEMENT} submit={createNewStatement} />
    </div>
  )
}

export default StatementsTable