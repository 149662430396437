import React from 'react'
import FormikDataForm from '../FormikDataForm'
import credentialSchema from '../schemas/credentialSchema'


const initialCredential = {
  username: '',
  password: ''
}
const LoginForm = ({ onSubmit }) => {
  const handleSubmit = (data) => {
    onSubmit(data)
  }

  return (
    <FormikDataForm
      initData={initialCredential}
      label={'login.form.header'}
      schema={credentialSchema}
      submitSchema={credentialSchema}
      handleSubmit={handleSubmit}>
      <FormikDataForm.Input label={'login.form.username'} fieldName={'username'}/>
      <FormikDataForm.Input label={'login.form.password'} fieldName={'password'}/>
      <FormikDataForm.SubmitButton label={'login.form.save'}/>
    </FormikDataForm>
  )
}

export default LoginForm
