import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid } from '@mui/material'

const ProgressWrapper = (props) => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setLoading(props.load)
    }, 200)
  }, [props.load])
  return (
    <>
      {
        loading ?
          <Grid container justifyContent="center" alignItems="center" style={{ height: '50vh' }}>
            <CircularProgress
              color="primary"
              variant="indeterminate"
            />
          </Grid> :<>
            {props.children}
          </>
      }
    </>
  )
}

export default ProgressWrapper