import { GET } from './api'
import { urls } from './urls'

export const balances = {
  runStatementBalance: async (statementId) => await GET(urls.balance.statementBalance + `${statementId}`),
  getLastOrgBalance: async () => await GET(urls.balance.lastBalance),
  runExpensesBalance: async () => await GET(urls.balance.expensesBalance),
  getBalanceById: async (id) => await GET(urls.balance.balanceHistory + `/${id}`),
  getOrgBalanceHistory: async (page, limit) => await GET(urls.balance.balanceHistory + `?page=${page}&limit=${limit}`),
  getOrgBalanceTransactions: async (id, page, limit) => await GET(urls.balance.balanceTransactions + `${id}?page=${page}&limit=${limit}`),
  getOrgBalanceAllTypes: async (id, type, page, limit) => await GET(urls.balance.balanceAllTypes + `${type}/${id}?page=${page}&limit=${limit}`),
}