import React from 'react'
import { Container } from '@mui/material'

const ContainerWrapper = ({ children, size = 'md' }) => {
  return (
    <Container maxWidth={size}>
      {children}
    </Container>
  )
}

export default ContainerWrapper
