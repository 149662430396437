import React from 'react'
import { Avatar, Card, CardContent, styled } from '@mui/material'
import { usedColor } from '../../../theme/palette'
import CustomTypography from '../../../components/commons/customs/CustomTypography'
import TextField from '@mui/material/TextField'

const MemebrtCard = ({ data }) => {
  const StyledCard = styled(Card)`
    color: ${usedColor.primary.main};
    width: 100%;
      display: flex;
    border: 2px solid ${usedColor.common['1']};
    border-radius: 10px;
    font-size: 46px;
    margin-bottom: 10px;
    padding: 1px;
  `
  console.log(data.image)
  return (
    <StyledCard>
      <Avatar
        sx={{
          width: 100,
          height: 100,
          margin: '10px',
        }}
        alt={data.name}
        src={'./images/'+ data.image+ '.jpg'}
      />
      <CardContent sx={{ flex: '1 0 auto' }}>
        <CustomTypography type={'6'} text={data.name} />
        <CustomTypography type={'7'} text={data.email} />
        <TextField
          fullWidth
          multiline={true}
          variant="standard"
          disabled={true}
          id={'info'}
          InputProps={{ disableUnderline: true }}
          name={'info'}
          defaultValue={data?.info}
        />

      </CardContent>

    </StyledCard>
  )
}

export default MemebrtCard