import React from 'react'
import TestGrid from '../../../theme/TestGrid'
import Box from '@mui/material/Box'
import { IOSSwitch } from '../utils/selectComponentObject'

const LabelsSwitch = ({ id, value, label }) => {

  return (
    <TestGrid>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }} >
        <IOSSwitch
          color={'success'}
          id={id}
          name={id}
          checked={value}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Box>
    </TestGrid>

  )
}

export default LabelsSwitch