import { DELETE, GET, POST } from './api'
import { urls } from './urls'


export const transaction = {
  createTransaction: async (data) => await POST(urls.transactions.base, data),
  getAllTransactions: async (page, limit) => await GET(urls.transactions.base + `?page=${page}&limit=${limit}`),
  getAllDepositsTransactions: async (page, limit) => await GET(urls.transactions.deposits + `?page=${page}&limit=${limit}`),
  getPersonTransactions: async (personId, page, limit) => await GET(urls.transactions.base + `/${personId}?page=${page}&limit=${limit}`),
  addTransactionAmountToPersonsSaldo: async () => await GET(urls.transactions.check),
  deleteUnChecked: async (id) => await DELETE(urls.transactions.base + `/${id}`),

}