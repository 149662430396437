import { createTheme } from '@mui/material'
import { customDay, usedColor } from './palette'

export function components() {

  const defaultTheme = createTheme()
  return {
    MuiTypography: {
      variant: [
        {
          props: { variant: 'showInfo' },
          style: {
            margin: '0px'
          }
        }
      ]
    },
    MuiAppBar: {
      height: '300px'
    },
    MuiTextField: {
      root: {
        border: `1px solid ${usedColor.common['1']}`,
        borderRadius:' 5px',
        '&:hover': {
          border: `1px solid ${usedColor.secondary.main}`,
        },
      }
    },
    MuiInput: {
      input: {
        color: 'red'
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: usedColor.primary.main,
          boxSizing: 'border-box',
          textAlign: 'center',
          width: '100%',
          boxShadow: '20px 20px 20px 20pxrgba(0,0,0,0.16)',
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'dashed' },
          style: {
            textTransform: 'none',
            border: `2px dashed ${defaultTheme.palette.primary.main}`,
            color: defaultTheme.palette.primary.main,
          },
        }, {
          props: { variant: 'main' },
          style: {
            boxShadow: ' 0 4px 8px rgba(0, 0, 0, 0.1)',
            padding: '0.5rem 1.5rem 0.5rem 1.5rem',
            marginLeft: '1rem',
            border: `1px ${customDay.background1}`,
            backgroundColor: `${customDay.background1}`,
            color:  `${customDay.primary}`,
            '&:hover': {
              border: `1px ${customDay.background1}`,
              backgroundColor: `${customDay.background1}`,
              color:  `${customDay.secondary}`,
            },
            '&:disabled': {
              backgroundColor: `${customDay.disabled}`
            }
          },
        },

        {
          props: { variant: 'dashed', color: 'secondary' },
          style: {
            border: `2px dashed ${defaultTheme.palette.secondary.main}`,
            color: defaultTheme.palette.secondary.main,
          },
        },
        {
          props: { variant: 'dashed', color: 'secondary' },
          style: {
            border: `2px dashed ${defaultTheme.palette.secondary.main}`,
            color: defaultTheme.palette.secondary.main,
          },
        },
        {
          props: { variant: 'usedColor', size: 'large' },
          style: {
            padding: '0.5rem 1.5rem 0.5rem 1.5rem',
            marginLeft: '1rem',
            border: `1px solid ${usedColor.primary.main}`,
            backgroundColor: usedColor.common['1'],
            '&:hover': {
              //border: `1px solid ${usedColor.secondary.main}`,
              backgroundColor: `${usedColor.primary.main}`,
              color:  usedColor.secondary.main,
            },
          },
        },
        {
          props: { variant: 'dashed', color: 'secondary', size: 'large' },
          style: {
            fontSize: 18,
          },
        },
      ],
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: 24,
        }
      }
    },
    MuiInputAdornment: {
      root: {
        fontSize: 24,
      }
    },
  }
}