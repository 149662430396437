import React, { createContext, useContext, useEffect, useState } from 'react'
import { STORAGE_KEY } from '../../utils/Enums'
import { usersApi } from '../../services/api/users'


const StorageContext = createContext(null)

export const useStorageContext = () => {
  return useContext(StorageContext)
}
const StorageProvider = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState(false)
  const [authenticatedUser, setAuthenticatedUser] = useState(null)
  const [updateTrigger, setUpdateTrigger] = useState(false)
  const [updateChild, setUpdateChild] = useState(false)

  const setUpdateTriggerData = () => setUpdateTrigger(prevTrigger => !prevTrigger)
  const setUpdateChildrData = () => setUpdateChild(prevTrigger => !prevTrigger)

  const userAuthentication = async () => {
    const str = localStorage.getItem(STORAGE_KEY.USER.toString())
    const token = JSON.parse(str)
    if (token?.token) {
      const user = await usersApi.isValid(token.token)
      setAuthenticated(!!user)
      setAuthenticatedUser(user)

    } else {
      setAuthenticated(false)
      setAuthenticatedUser(null)
    }
  }

  const updateUserInfo = (user) => {
    setAuthenticated(!!user)
    setAuthenticatedUser(user ? user : null)
  }

  useEffect(() => {
    userAuthentication().then()
  }, [])

  const value = {
    updateTrigger,
    setUpdateTriggerData,
    updateChild,
    setUpdateChildrData,
    isAuthenticated,
    authenticatedUser,
    updateUserInfo
  }
  return (
    <StorageContext.Provider value={value}>
      {children}
    </StorageContext.Provider>
  )
}

export default StorageProvider