import * as Yup from 'yup'
import { appSchema } from './schemaRoles'

export const userSchema = Yup.object({
  name: appSchema.requiredStringMinMax(2, 20, 'user.name'),
  username: appSchema.requiredStringMinMax(2, 20, 'user.username'),
  email:appSchema.requiredEmail('email'),
  password: appSchema.requiredPassword('password'),

})


export const updateUserSchema = Yup.object({
  name: appSchema.requiredStringMinMax(2, 20, 'user.name'),
  username: appSchema.requiredStringMinMax(2, 20, 'user.username'),
  email:appSchema.requiredEmail('email'),
  password: appSchema.notRequiredPassword('password'),

})