import React, { useEffect, useState } from 'react'
import { UnAuthAPI } from '../../../services/api/Persons'
import CustomTypography from '../../../components/commons/customs/CustomTypography'
import ReadOnlyForm from '../../../components/StyledComponents/readableOnly/ReadOnlyForm'
import CustomSelect from '../../../components/commons/customs/CustomSelect'
import { getCurrentDate } from '../../../utils/methods'
import PersonCard from '../../users/PersonCard'
import ExpenseCard from './ExpenseCard'

const Index = () => {
  const [lastBalance, setLastBalance] = useState({})
  const [expenses, setExpenses] = useState([])
  const [month, setMonth] = useState(getCurrentDate()['month'])


  const getLastBalance = async () => {
    try {
      const balance = await UnAuthAPI.getLastBalance()
      setLastBalance(balance)
    } catch (e) {
      console.log(e)
    }
  }

  const getExpensesByMonth= async () => {
    try {
      const expenses = await UnAuthAPI.getMonthExpenses(month)
      setExpenses(expenses)
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    getLastBalance().catch()
  }, [])

  useEffect(() => {
    getExpensesByMonth().catch()
    // eslint-disable-next-line
  }, [month])

  const handleChange = (data) => {
    setMonth(data?.target?.value)
  }

  return (
    <div>
      <CustomTypography text={'publicText.orgBalance'} type={'1'} variant={'h4'}/>
      <br/>
      <ReadOnlyForm data={lastBalance} label={''}>
        <ReadOnlyForm.Currency id={'balance'} label={'publicText.balance'}/>
        <ReadOnlyForm.Currency id={'deposited'} label={'publicText.deposited'}/>
        <ReadOnlyForm.Currency id={'notDeposited'} label={'publicText.notDeposited'} positive={false}/>
      </ReadOnlyForm>
      <CustomTypography text={'publicText.expenses'} type={'1'} variant={'h4'}/>
      <CustomSelect fieldName={'month'} label={'commons.month'} handleChange={handleChange} defaultValue={month}/>
      <br/>
      <PersonCard>
        {expenses?.map((expense, i) => <ExpenseCard key={i} expense={expense}/>)}
      </PersonCard>

    </div>
  )
}

export default Index