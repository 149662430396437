import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UnAuthAPI } from '../../../services/api/Persons'
import StyledNumber from '../../../components/commons/customs/StyledNumber'
import CustomTextField from '../../../components/commons/customs/CustomTextField'
import CustomTypography from '../../../components/commons/customs/CustomTypography'
import List from '../commons/PublicPersonsList'
import { Pagination } from '@mui/material'

const Index = () => {

  const { t } = useTranslation()
  const [formatted, setFormatted] = useState([])
  const [persons, setPersons] = useState({})
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')

  const fetchPersons = async (pageParameter, limitParameter, searchData) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const data =  await UnAuthAPI.getAllPersons(pageParameter, limitParameter, encodeURIComponent(searchData))
      const formatted = data?.results.map(person => formatRowCells(person, t))
      setPersons(data)
      setFormatted(formatted)
      setPage(data.page)
    } catch (e) {
      throw e
    }
  }
  useEffect(() => {
    fetchPersons(page, 5, search).
      catch(err => console.error(err.message))
    // eslint-disable-next-line
    }, [search, page])

  const handelPagination = async (e) => {
    if (e?.target?.textContent  && page !== e?.target?.textContent - 1) {
      fetchPersons(e?.target?.textContent - 1, 5, search).catch(
        err => console.error(err.message)
      )
    }
  }

  const handleSearch = (data)  => {
    setPage(0)
    setSearch(data)
  }

  return (
    <div>
      <CustomTypography text={'publicText.persons'} type={'1'} variant={'h4'}/>
      <CustomTextField fieldaname={'search'} value={search} label={'commons.search'} onchange={handleSearch}/>
      <List fetchData={formatted}/>
      <Pagination
        hideNextButton={true}
        hidePrevButton={true}
        count={persons?.totalPages}
        variant="outlined"
        color="primary" dir={'ltr'}
        page={page +1}
        onChange={handelPagination} />
    </div>
  )
}

export default Index


const formatRowCells = (person, t) => ({
  ['id']: person?.id,
  ['fullName']: `${person?.firstname} ${person?.surname}`,
  ['family']: t('publicText.familyCount',{ familyCount: person?.familyCount } ),
  ['isActive']: person?.account.isActive ? null : <CustomTypography key={'isActive'} text={'publicText.isNotActive'} type={'4'}/>,
  ['balance']: person?.account ?
    <StyledNumber key={person?.id} number={person?.account.balance} /> :
    <CustomTypography text={'commons.noAccount'} key={person?.id} type={'1'}/>,
})