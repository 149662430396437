import React from 'react'
import { useRouter } from '../../routes/useRoutes'
import CustomButton from './customs/CustomButton'

const BackButton = () => {
  const route = useRouter()
  return (
    <CustomButton text={'commons.back'} handleClick={() => route.back()} />
  )
}

export default BackButton
