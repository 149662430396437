import ThemeWrapper from './theme/ThemeWrapper'
import { Route, Routes } from 'react-router-dom'
import OwnAppBar from './components/dashboard/OwnAppBar'
import { AppSettingProvider } from './components/providers/AppSettingProvider'
import ContainerWrapper from './components/wrappers/ContainerWrapper'
import MessageProvider from './components/providers/MessageProvider'
import BottomDrawer from './components/dashboard/BottomDrawer'
import Footer from './components/dashboard/Footer'
import AppErrorBoundary from './services/ErrorBoundary/AppErrorBoundary'
import PrivateHome from './pages/private/home/privateHome'
import { useStorageContext } from './components/providers/StorageProvider'
import WrapperRouter from './routes/WrapperRouter'
import { ROLE } from './utils/Enums'
import { PATHS } from './utils/contants'
import PersonsView from './pages/persons/PersonsView'
import TransactionsView from './pages/transactions/TransactionsView'
import PersonView from './pages/persons/PersonView'
import StatementsView from './pages/statements/StatementsView'
import PersonInfoPage from './pages/persons/PersonInfoPage'
import UsersList from './pages/users/UsersList'
import AllTypesTable from './pages/orgBalance/AllTypesTable'
import ExpenseInfo from './pages/Expenses/ExpenseInfo'
import ExpenseView from './pages/Expenses/ExpenseView'
import OrgBalanceView from './pages/orgBalance/OrgBalanceView'
import Logout from './pages/Logout'
import Actions from './pages/private/Actions'
import Persons from './pages/public/Persons'
import Balance from './pages/public/Balance'
import Donations from './pages/public/Donations'
import Members from './pages/public/Members'

const App = () => {

  const { updateUserInfo, authenticatedUser } = useStorageContext()
  return (
    <>
      <AppErrorBoundary>
        <AppSettingProvider>
          <ThemeWrapper>
            <OwnAppBar />
            <BottomDrawer />
            <ContainerWrapper>
              <MessageProvider>
                <Routes>
                  <Route path={'/'} element={<WrapperRouter authenticatedUser={authenticatedUser} role={ROLE.USER}><PrivateHome /></WrapperRouter>} />
                  <Route path={PATHS.logged.home} element={<WrapperRouter authenticatedUser={authenticatedUser} role={ROLE.USER}><PrivateHome /></WrapperRouter>} />
                  <Route path={PATHS.logged.actions} element={<WrapperRouter authenticatedUser={authenticatedUser} role={ROLE.MASTER}><Actions /></WrapperRouter>} />
                  <Route path={PATHS.logged.addPerson} element={<WrapperRouter authenticatedUser={authenticatedUser} role={ROLE.USER}><PersonView /></WrapperRouter>} />
                  <Route path={PATHS.logged.personInfo} element={<WrapperRouter authenticatedUser={authenticatedUser} role={ROLE.USER}><PersonInfoPage /></WrapperRouter>} />
                  <Route path={PATHS.logged.persons} element={<WrapperRouter authenticatedUser={authenticatedUser} role={ROLE.USER}><PersonsView /></WrapperRouter>} />
                  <Route path={PATHS.logged.transactions} element={<WrapperRouter authenticatedUser={authenticatedUser} role={ROLE.USER}><TransactionsView /></WrapperRouter>} />
                  <Route path={PATHS.logged.statements} element={<WrapperRouter authenticatedUser={authenticatedUser} role={ROLE.USER}><StatementsView /></WrapperRouter>} />
                  <Route path={PATHS.logged.orgBalance} element={<WrapperRouter authenticatedUser={authenticatedUser} role={ROLE.USER}><OrgBalanceView /></WrapperRouter>} />
                  <Route path={PATHS.logged.expense} element={<WrapperRouter authenticatedUser={authenticatedUser} role={ROLE.USER}><ExpenseView /></WrapperRouter>} />
                  <Route path={PATHS.logged.expenseInfo} element={<WrapperRouter authenticatedUser={authenticatedUser} role={ROLE.USER}><ExpenseInfo /></WrapperRouter>} />
                  <Route path={PATHS.logged.orgBalanceAllTypes} element={<WrapperRouter authenticatedUser={authenticatedUser} role={ROLE.USER}><AllTypesTable /></WrapperRouter>} />
                  <Route path={PATHS.logged.users} element={<WrapperRouter authenticatedUser={authenticatedUser} role={ROLE.MASTER}><UsersList /></WrapperRouter>} />
                  <Route path={PATHS.notLogged.notLoggedPersons} element={<Persons />} />
                  <Route path={PATHS.notLogged.notLoggedBalance} element={<Balance />} />
                  <Route path={PATHS.notLogged.notLoggedDonations} element={<Donations />} />
                  <Route path={PATHS.notLogged.notLoggedMembers} element={<Members />} />

                  <Route path={PATHS.logged.logout} element={<Logout func={updateUserInfo}/>} />
                </Routes>
              </MessageProvider>
            </ContainerWrapper>
            <Footer />
          </ThemeWrapper>
        </AppSettingProvider>
      </AppErrorBoundary>
    </>
  )
}

export default App