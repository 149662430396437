import { alpha } from '@mui/material/styles'

// ----------------------------------------------------------------------

// SETUP COLORS

export const grey = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
}

export const customDarkGreen = {
  50: '#8FA3A3',
  100: '#7A8F8F',
  200: '#667B7B',
  300: '#516767',
  400: '#3D5353',
  500: '#294040',
  600: '#233737',
  700: '#1D2E2E',
  800: '#172626',
  900: '#111D1D',
}

export const customGreen = {
  50: '#C2D8D7',
  100: '#A6CFCD',
  200: '#89C6C2',
  300: '#6CBDB8',
  400: '#56B6B1',
  500: '#3FAFAA',
  600: '#38A6A2',
  700: '#309C99',
  800: '#299390',
  900: '#1D837F',
}



export const primary = {
  lighter: '#D0ECFE',
  light: '#73BAFB',
  main: '#1877F2',
  dark: '#0C44AE',
  darker: '#042174',
  contrastText: '#FFFFFF',
}

export const secondary = {
  lighter: '#EFD6FF',
  light: '#C684FF',
  main: '#8E33FF',
  dark: '#5119B7',
  darker: '#27097A',
  contrastText: '#FFFFFF',
}

export const info = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#FFFFFF',
}

export const success = {
  lighter: '#C8FAD6',
  light: '#5BE49B',
  main: '#00A76F',
  dark: '#007867',
  darker: '#004B50',
  contrastText: '#FFFFFF',
}

export const warning = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: grey[800],
}

export const error = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#FFFFFF',
}

export const common = {
  black: '#000000',
  white: '#FFFFFF',
}

export const customDay = {
  primary: grey['100'],
  secondary: customGreen['500'],
  background1: '#27363A',
  background2: '#27363A',
  disabled: '#DCDCDC',
  info: '',
  success: customGreen['900'],
  warning: warning.dark,
  error: error.main,
  common: '',
  divider: alpha(grey[500], 0.2),
  action: '',
}
/*

#CDCBD6
#D96846
#596235
#2F3020

____
#F9AA33
#344955
 */

export const usedColor = {
  primary: {
    lighter: '#5E788C',
    light: '#4E6578',
    main: '#344955',
    dark: '#2A3B4C',
    darker: '#1F2A39',
  },
  secondary: {
    lighter: '#FBD57C',
    light: '#FACB66',
    main: '#F9AA33',
    dark: '#D18E2A',
    darker: '#A87521',
  },
  common: {
    1: '#DCDCDC',
    2: '#2F3020',
  },
  info: {
    lighter: '#CAFDF5',
    light: '#61F3F3',
    main: '#00B8D9',
    dark: '#006C9C',
    darker: '#003768',
    contrastText: '#FFFFFF',
  },
  success: {
    lighter: '#C8FAD6',
    light: '#5BE49B',
    main: '#00A76F',
    dark: '#007867',
    darker: '#004B50',
    contrastText: '#FFFFFF',
  },
  warning: {
    lighter: '#FFF5CC',
    light: '#FFD666',
    main: '#FFAB00',
    dark: '#B76E00',
    darker: '#7A4100',
  },
  error: {
    lighter: '#FFE9D5',
    light: '#FFAC82',
    main: '#FF5630',
    dark: '#B71D18',
    darker: '#7A0916',
    contrastText: '#FFFFFF',
  },
}
export const customNight = {
  primary: '',
  secondary: '',
  info: '',
  success: '',
  warning: '',
  error: '',
  common: '',
  divider: alpha(grey[500], 0.2),
  action: '',
}

export const action = {
  hover: alpha(grey[500], 0.08),
  selected: alpha(grey[500], 0.16),
  disabled: alpha(grey[500], 0.8),
  disabledBackground: alpha(grey[500], 0.24),
  focus: alpha(grey[500], 0.24),
  hoverOpacity: 0.08,
  disabledOpacity: 0.48,
}

const base = {
  primary,
  secondary,
  info,
  success,
  warning,
  error,
  grey,
  common,
  divider: alpha(grey[500], 0.2),
  action,
  customDay,
  customNight
}

// ----------------------------------------------------------------------

export function palette(mode = 'dark') {
  return {
    ...base,
    mode: 'light',
    primary: {
      ...customDarkGreen,
      ...(mode === 'dark' && {
        main: customDarkGreen[500],
      }),
    },
    text: {
      primary: grey[800],
      secondary: grey[600],
      disabled: grey[500],
    },
    background: {
      paper: '#FFFFFF',
      default: grey[100],
      neutral: grey[200],
    },
    action: {
      ...base.action,
      active: grey[600],
    },
  }
}
