import React, { useEffect, useState } from 'react'
import ButtonGroupWithGrid from '../../../components/commons/grid/ButtonGroupWithGrid'
import CustomButton from '../../../components/commons/customs/CustomButton'
import TransactionForm from '../../../components/dataForm/forms/TransactionForm'
import PersonTransactionsTable from '../Transactions/PersonTransactionsTable'
import ReadOnlyForm from '../../../components/StyledComponents/readableOnly/ReadOnlyForm'
import { useTranslation } from 'react-i18next'
import { tableFormatRowCell } from '../../../data/rows_data'
import { TABLE_TYPE } from '../../../utils/Enums'
import { Stack } from '@mui/material'
import { IOSSwitch } from '../../../components/StyledComponents/utils/selectComponentObject'
import get from 'lodash/get'
import { useStorageContext } from '../../../components/providers/StorageProvider'
import { account } from '../../../services/api/accounts'

const Account = ({ accountData }) => {
  const { setUpdateTriggerData } = useStorageContext()
  const { t } = useTranslation()
  const [formatted, setFormatted] = useState(undefined)

  useEffect(() => {
    setFormatted(tableFormatRowCell[TABLE_TYPE.ACCOUNT](accountData, t))
    // eslint-disable-next-line
  }, [accountData])

  const [openTransactionForm, setOpenTransactionForm] = useState(false)
  const handleAccountActiveChanges = async () => {
    await account.updateActive(formatted?.id, { isActive: formatted?.isActive }).then(() => {
      setUpdateTriggerData()
    }).catch(e => console.error(e))
  }

  return (
    <Stack sx={{ marginTop: '2rem', marginBottom: '2rem' }}>
      <IOSSwitch
        id={'isActive'}
        name={'isActive'}
        onChange={handleAccountActiveChanges }
        checked={get(accountData, 'isActive')}/>
      <br/>
      <br/>
      <ReadOnlyForm data={formatted}>
        <ReadOnlyForm.Input id={'openedAt'} label={'person.accounts.openedAt'} />
        <ReadOnlyForm.Currency id={'balance'} label={'person.accounts.balance'} />
      </ReadOnlyForm>

      <ButtonGroupWithGrid>
        {!openTransactionForm &&
            <CustomButton text={'person.accounts.add'} handleClick={() => setOpenTransactionForm(true)}/>}
      </ButtonGroupWithGrid>

      {openTransactionForm && <TransactionForm account={accountData} close={() => setOpenTransactionForm(false)} />}
      <PersonTransactionsTable account={accountData} />
    </Stack>
  )
}

export default Account