import React, { useState } from 'react'
import StyledIconButton from '../../../components/commons/customs/StyledIconButton'
import { transaction } from '../../../services/api/transactions'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import CustomTypography from '../../../components/commons/customs/CustomTypography'
import { balances } from '../../../services/api/balances'
import EuroIcon from '@mui/icons-material/Euro'
import { Stack } from '@mui/material'
import { useMessageContext } from '../../../components/providers/MessageProvider'
import CustomButton from '../../../components/commons/customs/CustomButton'
import PrintIcon from '@mui/icons-material/Print'
import { pdfPrints } from '../../../services/api/pdfPrints'
import { getCurrentDate } from '../../../utils/methods'
import CustomSelect from '../../../components/commons/customs/CustomSelect'

const Index = () => {
  const { setCommonModalData, setInfoModal } = useMessageContext()
  const [printDate, setPrintDate] = useState({
    month: getCurrentDate().month,
    year: getCurrentDate().year
  })

  const handleAddingPersonsSaldo = async () => {
    setCommonModalData({ open: false })
    try {
      await transaction.addTransactionAmountToPersonsSaldo()
    } catch (e) {
      if (e?.response?.data?.error) {
        setInfoModal({
          title: `${'service.'+ e?.response?.data?.error}`,
        })
      } else {
        console.error(e)
      }

    }
  }

  const handleSubtractAction = async () => {
    setCommonModalData({ open: false })
    try {
      await balances.runExpensesBalance()
    } catch (e) {
      if (e?.response?.data?.error) {
        setInfoModal({
          title: `${'service.'+ e?.response?.data?.error}`,
        })
      } else {
        console.error(e)
      }
    }
  }

  const handleTransactionsPrint = async () => {
    setCommonModalData({ open: false })


    try {
      if (printDate.year <= getCurrentDate().year && printDate.month <= getCurrentDate().month) {
        const emails = await pdfPrints.transactionsPrint(printDate.year, printDate.month)
        setInfoModal({
          title: 'service.print-success',
          context: emails
        })
      } else {
        setInfoModal({
          title: 'service.date-in_future',
        })
      }

    } catch (e) {
      if (e?.response?.data?.error) {
        setInfoModal({
          title: `${'service.'+ e?.response?.data?.error}`,
        })
      } else {
        console.error(e)
      }
    }
  }
  const actionsModels = {
    addSaldo: () => setCommonModalData({
      title: 'actions.transaction.modal.title',
      content: 'actions.transaction.modal.content',
      open: true,
      buttons: [
        <CustomButton
          text={'actions.transaction.modal.yes'}
          handleClick={() => handleAddingPersonsSaldo()}
          key={'addSaldo'} />,
      ]
    }),
    subtract : () => setCommonModalData({
      title: 'actions.expense.modal.title',
      content: 'actions.expense.modal.content',
      open: true,
      buttons: [
        <CustomButton
          text={'actions.expense.modal.yes'}
          handleClick={() => handleSubtractAction()}
          key={'subtract'} />,
      ]
    }),
    transactionPrint: () => setCommonModalData({
      title: 'actions.print.modal.title',
      content: 'actions.print.modal.content',
      open: true,
      buttons: [
        <CustomButton
          text={'actions.print.modal.yes'}
          handleClick={() => handleTransactionsPrint()}
          key={'printTransactions'} />,
      ]
    })

  }

  const handleAction = (key) => {
    actionsModels[key]()
  }

  const handlePrintDate = (data) => {
    setPrintDate(prevPrintDate => {
      return {
        ...prevPrintDate,
        [data.target.name]: data.target.value
      }
    })

  }
  return (
    <>
      <CustomTypography text={'dashBoard.right.actions'} variant={'h4'}/>
      <Stack direction={'column'} >
        <StyledIconButton
          key={'addSaldo'}
          text={'transaction.modal.addSaldo'}
          handleClick={() => handleAction('addSaldo')}
          icon={<AccountBalanceIcon />}/>

        <StyledIconButton
          key={'subtract'}
          text={'expense.subtract'}
          handleClick={() => handleAction('subtract')}
          icon={<EuroIcon />}/>

        <Stack direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}>
          <div style={{ marginLeft: '20px', marginRight: '20px' }}>
            <CustomSelect fieldName={'month'} label={'commons.month'}  defaultValue={printDate.month} handleChange={handlePrintDate}/>
          </div>
          <div>
            <CustomSelect fieldName={'year'} label={'commons.year'}  defaultValue={printDate.year} handleChange={handlePrintDate}/>
          </div>


        </Stack>


        <StyledIconButton
          key={'printTransactions'}
          text={'transaction.print'}
          handleClick={() => handleAction('transactionPrint')}
          icon={<PrintIcon />}/>
      </Stack>

    </>

  )
}

export default Index