import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { personsAPI } from '../../services/api/Persons'
import { Avatar, Typography } from '@mui/material'
import StyledNumber from '../../components/commons/customs/StyledNumber'
import CommonTable from '../../components/commons/CommonTable'
import CustomButton from '../../components/commons/customs/CustomButton'
import ButtonGroupWithGrid from '../../components/commons/grid/ButtonGroupWithGrid'
import { PATHS } from '../../utils/contants'
import { useRouter } from '../../routes/useRoutes'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import { usedColor } from '../../theme/palette'
import CustomTextField from '../../components/commons/customs/CustomTextField'


const PersonsTable = () => {
  const { t } = useTranslation()
  const [persons, setPersons] = useState({})
  const [formatted, setFormatted] = useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [search, setSearch] = useState('')
  const router = useRouter()

  const fetchPersons = async (pageParameter, limitParameter) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const data =  await personsAPI.getAllPersons(pageParameter, limitParameter, search)
      setPersons(data)
      const formatted = data?.results.map(person => formatRowCells(person, t))
      setFormatted(formatted)
      setPage(data.page)
    } catch (e) {
      throw e
    }
  }
  useEffect(() => {
    fetchPersons(page, rowsPerPage).
      catch(err => console.error(err.message))
    // eslint-disable-next-line
  }, [search])

  const handleChosenItem = (person) => {
    if (person && person.id) {
      router.push(PATHS.logged.persons + `/${person.id}`)
    }
  }

  const handleChangePage = (event, newPage) => {
    fetchPersons(newPage, rowsPerPage).catch(err => console.error(err.message))
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    console.log('Page has been changed', event)
    setRowsPerPage(event.target.value)
    fetchPersons(page, event.target.value).catch(err => console.error(err.message))
    setPage(0)
  }
  const addPerson = () => {
    router.push(PATHS.logged.addPerson)
  }
  const handleSearch = (data)  => {
    setPage(0)
    setSearch(data)
  }

  return (
    <div>
      <CustomTextField fieldaname={'search'} value={search} label={'commons.search'} onchange={handleSearch}/>
      <CommonTable
        columns={columns}
        page={page}
        rowsPerPage={rowsPerPage}
        rows={formatted}
        totalRows={persons.total || 0}
        onRowClick={handleChosenItem}
        onPageChange={handleChangePage}
        onRowPerPage={handleChangeRowsPerPage}
      />
      <ButtonGroupWithGrid>
        <CustomButton text={'person.table.add'} handleClick={() => addPerson()} />
      </ButtonGroupWithGrid>
    </div>
  )
}

export default PersonsTable

const formatRowCells = (person, t) => ({
  ['id']: person?.id,
  ['picture']: <Avatar alt={person?.firstname+ person?.surname} sx={{ marginTop: '5px', marginBottom: '5px', marginRight: '20px' }}/>,
  ['fullName']: `${person?.firstname} ${person?.surname}`,
  ['balance']: person.account ?  <StyledNumber number={person?.account.balance}/> : <Typography>{t('commons.noAccount')}</Typography>,
  ['isActive']: person?.account && person?.account.isActive ?  <SentimentSatisfiedAltIcon sx={{ color: usedColor.success.dark }}/> : <SentimentVeryDissatisfiedIcon sx={{ color: usedColor.error.dark }}/>
})

const columns = [
  {
    id: 'isActive',
    label: '',
    width: 50,
  },
  {
    id: 'fullName',
    label: 'person.table.fullName',
    width: 90,
  },
  {
    id: 'balance',
    label: 'person.table.balance',
    width: 50,
  },


]
