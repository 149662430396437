import HomeIcon from '@mui/icons-material/Home'
import GroupsIcon from '@mui/icons-material/Groups'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import BalanceIcon from '@mui/icons-material/Balance'
import EuroIcon from '@mui/icons-material/Euro'
import FaceIcon from '@mui/icons-material/Face'
import CalculateIcon from '@mui/icons-material/Calculate'
import LogoutIcon from '@mui/icons-material/Logout'
import LoginIcon from '@mui/icons-material/Login'
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism'
import EngineeringIcon from '@mui/icons-material/Engineering'
import * as React from 'react'

export const PATHS = {
  logged: {
    home: '/logged',
    actions: '/logged/actions',
    transactions: '/logged/transactions',
    statements: '/logged/statements',
    orgBalance: '/logged/organisationBalance',
    users: '/logged/users',
    expenseInfo: '/logged/expense/:id',
    expense: '/logged/expense',
    orgBalanceAllTypes: '/logged/organisationBalanceHistory/:type/:id',
    persons: '/logged/persons',
    addPerson: '/logged/addPerson',
    personInfo: '/logged/persons/:id',
    logout: '/logout'
  },
  notLogged: {
    notLoggedTransactions: '/transactions',
    notLoggedPersons: '/persons',
    notLoggedBalance: '/balance',
    notLoggedDonations: '/donation',
    notLoggedMembers: '/members',
    home: '/logged'
  }
}

export const addPerson = [{
  id: 'addPerson',
  title: 'Add Person',
  icon: <PersonAddIcon color='inherit' sx={{ fontSize: 'inherit' }} />,
  path: PATHS.logged.addPerson,
  showInDrawer: true,
}]

export const home = [
  {
    id: 'home',
    title: 'Home',
    icon: <HomeIcon color='inherit' sx={{ fontSize: 'inherit' }}  />,
    path: PATHS.logged.home,
    showInDrawer: true,
  },
]
export const rightDrawerObject = [
  {
    id: 'persons',
    title: 'Persons',
    icon: <GroupsIcon color='inherit' sx={{ fontSize: 'inherit' }}/>,
    path: PATHS.logged.persons,
    showInDrawer: true,
  },
  {
    id: 'transactions',
    title: 'transactions',
    icon: <AccountBalanceIcon color='inherit' sx={{ fontSize: 'inherit' }}/>,
    path: PATHS.logged.transactions,
    showInDrawer: true,
  },
  {
    id: 'statements',
    title: 'statements',
    icon: <CreditCardIcon color='inherit' sx={{ fontSize: 'inherit' }}/>,
    path: PATHS.logged.statements,
    showInDrawer: true,
  },
  {
    id: 'expense',
    title: 'expense',
    icon: <EuroIcon color='inherit' sx={{ fontSize: 'inherit' }}/>,
    path: PATHS.logged.expense,
    showInDrawer: true,
  },
  {
    id: 'orgBalance',
    title: 'orgBalance',
    icon: <BalanceIcon color='inherit' sx={{ fontSize: 'inherit' }}/>,
    path: PATHS.logged.orgBalance,
    showInDrawer: true,
  },
  {
    id: 'users',
    title: 'users',
    icon: <FaceIcon color='inherit' sx={{ fontSize: 'inherit' }}/>,
    path: PATHS.logged.users,
    showInDrawer: false,
  },
  {
    id: 'actions',
    title: 'actions',
    icon: <CalculateIcon color='inherit' sx={{ fontSize: 'inherit' }}/>,
    path: PATHS.logged.actions,
    showInDrawer: false,
  },
  {
    id: 'logout',
    title: 'logout',
    icon: <LogoutIcon color='inherit' sx={{ fontSize: 'inherit' }}/>,
    path: PATHS.logged.logout,
    showInDrawer: true,
  },
]

export const unauthenticatedDrawerObject = [
  {
    id: 'persons',
    title: 'persons',
    icon: <GroupsIcon color='inherit' sx={{ fontSize: 'inherit' }}/>,
    path: PATHS.notLogged.notLoggedPersons,
    showInDrawer: true,
  },
  {
    id: 'orgBalance',
    title: 'orgBalance',
    icon: <BalanceIcon color='inherit' sx={{ fontSize: 'inherit' }}/>,
    path: PATHS.notLogged.notLoggedBalance,
    showInDrawer: true,
  },
  {
    id: 'donation',
    title: 'donation',
    icon: <VolunteerActivismIcon color='inherit' sx={{ fontSize: 'inherit' }}/>,
    path: PATHS.notLogged.notLoggedDonations,
    showInDrawer: true,
  },
  {
    id: 'members',
    title: 'members',
    icon: <EngineeringIcon color='inherit' sx={{ fontSize: 'inherit' }}/>,
    path: PATHS.notLogged.notLoggedMembers,
    showInDrawer: true,
  },
  {
    id: 'login',
    title: 'login',
    icon: <LoginIcon color='inherit' sx={{ fontSize: 'inherit' }}/>,
    path: PATHS.logged.home,
    showInDrawer: true,
  },
]