import React, { useEffect, useState } from 'react'
import { balances } from '../../services/api/balances'
import Divider from '@mui/material/Divider'
import BalanceInfo from './BalanceInfo'

const LastBalanceView = () => {

  const [balanceData, setBalanceData] = useState({})

  const fetchLastalance = async () => {
    return await balances.getLastOrgBalance()
  }

  useEffect(() => {
    fetchLastalance().then((data) => {
      setBalanceData(data?.orgBalance)
    }).catch(erro => console.error(erro.message))
  }, [])

  return (
    <div>
      <Divider sx={{ marginTop: '2rem', marginBottom: '2rem' }}></Divider>
      <BalanceInfo balance={balanceData}/>
    </div>
  )
}

export default LastBalanceView