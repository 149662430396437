import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { balances } from '../../services/api/balances'
import { tableFormatRowCell } from '../../data/rows_data'
import { tableColumns } from '../../data/data'
import _ from 'lodash'
import CommonTable from '../../components/commons/CommonTable'
import BackButton from '../../components/commons/BackButton'
import ButtonGroupWithGrid from '../../components/commons/grid/ButtonGroupWithGrid'
import { TABLE_TYPE } from '../../utils/Enums'
import { useTranslation } from 'react-i18next'

const AllTypesTable = () => {
  const { id, type } = useParams()
  const { t } = useTranslation()
  const balanceType = type === TABLE_TYPE.STATEMENT ? TABLE_TYPE.TRANSACTION : type
  const [formatted, setFormatted] = useState([])

  const [data, setData] = useState({})
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const fetchAllTypesData = async (pageParameter, limitParameter) => {
    try {
      const data = await balances.getOrgBalanceAllTypes(id, type, pageParameter, limitParameter)
      setData({ [balanceType]: data })
      const formatted = data?.results.map(s => tableFormatRowCell[balanceType](s, t))
      setFormatted(formatted)
      setPage(data.page)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    fetchAllTypesData(page, rowsPerPage).then()
    // eslint-disable-next-line
  }, [ page, rowsPerPage])

  const handleChangePage = (event, newPage) => {
    fetchAllTypesData(newPage, rowsPerPage).catch(err => console.error(err.message))
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    fetchAllTypesData(page, event.target.value).catch(err => console.error(err.message))
    setPage(0)
  }

  const handleOpen = () => {}
  return (
    <div>
      <CommonTable
        columns={tableColumns[balanceType]}
        page={page}
        rowsPerPage={rowsPerPage}
        rows={_.reverse([...formatted])}
        totalRows={data[balanceType]?.total || 0}
        onRowClick={handleOpen}
        onPageChange={handleChangePage}
        onRowPerPage={handleChangeRowsPerPage}
      />
      <ButtonGroupWithGrid>
        <BackButton />
      </ButtonGroupWithGrid>
    </div>

  )
}

export default AllTypesTable
