import React, { createContext, useContext, useState } from 'react'
import InfoModal from '../commons/customs/InfoModal'
import { DATA_FROMS } from '../../utils/Enums'
import ActionModal from '../commons/customs/ActionModal'
import AppActionModal from '../commons/customs/AppActionModal'
import appActionModal from '../commons/customs/AppActionModal'

const MessageContext = createContext(null)

export const useMessageContext = () => {
  return useContext(MessageContext)
}
const MessageProvider = ({ children }) => {
  const [infoModal, setInfoModal] = useState({})
  const [commonModalData, setCommonModalData] = useState({})
  const [appModal, setAppModal] = useState({})
  const [formType, setFormType] = useState(DATA_FROMS.CLOSED)

  const value = {
    setInfoModal,
    setFormType,
    formType,
    appModal,
    setAppModal,
    setCommonModalData
  }
  return (
    <MessageContext.Provider value={value}>
      <InfoModal closing={() => setInfoModal({})} infoModal={infoModal}/>
      <ActionModal
        appModal={appModal}
        arg1={appModal.arg1}
        closing={() => setAppModal({})}
      />
      <AppActionModal
        title={commonModalData.title}
        content={commonModalData.content}
        buttons={commonModalData.buttons}
        open={commonModalData.open}
        arg1={commonModalData.arg1}
        closing={() => setCommonModalData({ open: false })}
      />
      {children}
    </MessageContext.Provider>
  )
}

export default MessageProvider