import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getDate } from '../../../utils/methods'
import { transaction } from '../../../services/api/transactions'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import { customDay } from '../../../theme/palette'
import { useStorageContext } from '../../../components/providers/StorageProvider'
import { useMessageContext } from '../../../components/providers/MessageProvider'
import CustomButton from '../../../components/commons/customs/CustomButton'
import CommonTable from '../../../components/commons/CommonTable'
import StyledNumber from '../../../components/commons/customs/StyledNumber'


const PersonTransactionsTable = ({ account }) => {
  const { t } = useTranslation()
  const { updateTrigger, setUpdateTriggerData } = useStorageContext()
  const { setInfoModal, setCommonModalData } = useMessageContext()
  const [chosenItem, setChosenItem] = useState(null)
  const [formatted, setFormatted] = useState([])
  const [transactions, setTransactions] = useState({})
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const fetchTransactionsData = async (pageParameter, limitParameter) => {
    try {
      const data =  await transaction.getPersonTransactions(account.person, pageParameter, limitParameter)
      setTransactions(data)
      const formatted = data?.results.map(s => formatRowCell(s, t))
      setFormatted(formatted)
      setPage(data.page)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    fetchTransactionsData(page, rowsPerPage).
      catch(err => console.error(err.message))
    // eslint-disable-next-line
  }, [updateTrigger, page, account, rowsPerPage])

  const handleChangePage = (event, newPage) => {
    fetchTransactionsData(newPage, rowsPerPage).catch(err => console.error(err.message))
    setPage(newPage)
  }
  useEffect(() => {
    setCommonModalData({
      title: 'transaction.modal.title',
      content: 'transaction.modal.content',
      open: chosenItem !== null,
      arg1: chosenItem?.amount,
      buttons: [
        <CustomButton
          text={'actions.transaction.modal.yes'}
          handleClick={() => handleTransactionDeleting()}
          key={'addSaldo'} />,
      ]
    })
    // eslint-disable-next-line
  }, [setChosenItem, chosenItem])

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    fetchTransactionsData(page, event.target.value).catch(err => console.error(err.message))
    setPage(0)
  }

  const handleTransactionDeleting = async () => {
    setCommonModalData({})
    try {
      await transaction.deleteUnChecked(chosenItem.id)
      setChosenItem(null)
      setUpdateTriggerData()
    } catch (e) {
      setInfoModal({
        title: `service.${e.response.data.error}` ,
      })
    }
  }

  const handleChosenItem = (transaction) => {
    if (transaction && transaction.isChecked) {
      setInfoModal({
        title: 'errors.transaction.isChecked.context',
      })
    } else {
      setChosenItem(transaction)
    }
  }

  return (
    <CommonTable
      columns={columns}
      page={page}
      rowsPerPage={rowsPerPage}
      rows={formatted}
      totalRows={transactions.total || 0}
      onRowClick={handleChosenItem}
      onPageChange={handleChangePage}
      onRowPerPage={handleChangeRowsPerPage}
    />
  )
}

export default PersonTransactionsTable

const formatRowCell = (transaction, t) => ({
  ['id']: transaction?.id,
  ['amount']: transaction?.amount,
  ['sequenceNumber']: transaction?.sequenceNumber,
  ['amountEuro']: <StyledNumber number={transaction?.amount} fontSize={'1rem'} type={'text'}/>,
  ['method']: t(`transaction.table.paymentMethods.${transaction?.paymentMethod}`),
  ['type']: t(`transaction.table.transactionTypes.${transaction?.transactionType}`),
  ['timestamp']: getDate(transaction?.timestamp),
  ['isChecked']: transaction?.isChecked,
  ['descriptionFormatted']: t(`transaction.table.descriptionSelect.${transaction?.description}`),
  ['isCheckedComponent']: transaction?.isChecked ? <DoneIcon sx={{ color: customDay.success }} /> : <CloseIcon sx={{ color: customDay.error }} />,

})


const columns = [
  {
    id: 'isCheckedComponent',
    label: 'transaction.table.isChecked',
    width: 30
  },
  {
    id: 'sequenceNumber',
    label: 'transaction.table.sequenceNumber',
    width: 30,
  },{
    id: 'amountEuro',
    label: 'transaction.table.amount',
    width: 30,
  },
  {
    id: 'descriptionFormatted',
    label: 'transaction.table.description',
    width: 50,
  },
  {
    id: 'method',
    label: 'transaction.table.paymentMethod',
    width: 30,
  },
  {
    id: 'type',
    label: 'transaction.table.transactionType',
    width: 30,
  },
  { id: 'timestamp',
    label: 'transaction.table.timestamp',
    width: 50
  },
]



