import React from 'react'
import StatementsTable from './StatementsTable'
import CustomTypography from '../../components/commons/customs/CustomTypography'

const StatementsView = () => {
  return (
    <>
      <CustomTypography text={'dashBoard.right.statements'} variant={'h4'}/>
      <StatementsTable />
    </>
  )
}

export default StatementsView