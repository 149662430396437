import React from 'react'
import { ROLE } from '../utils/Enums'
import LoginPage from '../pages/Login/LoginPage'
import CustomTypography from '../components/commons/customs/CustomTypography'
import BackButton from '../components/commons/BackButton'

const WrapperRouter = ({ role = ROLE.VIEWER, authenticatedUser, children }) => {

  const isAuthenticated = () => authenticatedUser && authenticatedUser.isActive
  const isAuthorized = () =>
    authenticatedUser?.role === ROLE.MASTER ? true :
      (authenticatedUser?.isActive && authenticatedUser?.role === role)

  const showPage = () => {
    if(isAuthenticated() && isAuthorized()) {
      return children
    } else if(isAuthenticated() && !isAuthorized()) {
      return <>
        <CustomTypography text={'login.form.master'} variant={'h5'}/>
        <BackButton />
      </>
    } else {
      return <LoginPage />
    }
  }
  return (
    <>
      { children ? showPage() : null}
    </>
  )
}

export default WrapperRouter