import React from 'react'
import { styled, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppSetting } from '../providers/AppSettingProvider'

const ItemText = ({ text }) => {
  const { t } = useTranslation()
  const { windowSize } = useAppSetting()
  const StyledTypo = styled(Typography)`
        color: inherit;
        font-weight: normal;
        font-size: ${windowSize.isDownMd ? '8px' : '12px'};
        :hover {
          color: inherit;
        }
    `

  return (
    <StyledTypo>
      {t(text)}
    </StyledTypo>
  )
}

export default ItemText