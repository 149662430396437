export const ALERT = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
}

export const TRANSACTION_TYPE = {
  CASH: 'CASH',
  BANK: 'BANK'
}

export const STATEMENT_TYPE = {
  MONTH_FEE: 'MONTH_FEE',
  OTHER_FEE: 'OTHER_FEE'
}
export const TRANSACTION_DESCRIPTION = {
  MONTH_FEE: 'MONTH_FEE',
  DONATION: 'DONATION',
}

export const DATA_FROMS = {
  STATEMENT: 'STATEMENT',
  EXPENSE: 'EXPENSE',
  USER: 'USER',
  LOGIN: 'LOGIN',
  CLOSED: 'CLOSED'
}

export const STORAGE_KEY = {
  USER: 'USER'
}

export const MONTHS = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec'
}

export const EXPENSES_CATEGORY = {
  RENT: 'RENT',
  ELECTRICITY: 'ELECTRICITY',
  MAINTENANCE: 'MAINTENANCE',
  WATER: 'WATER',
  TAXES: 'TAXES',
  INSURANCE: 'INSURANCE',
  DONATION: 'DONATION',
  BANK_FEES: 'BANK_FEES',
  TRAVEL_EXPENSES: 'TRAVEL_EXPENSES',
  UTILITIES: 'UTILITIES',
  EQUIPMENT: 'EQUIPMENT',
  OTHER: 'OTHER'
}

export const TABLE_TYPE = {
  STATEMENT: 'statement',
  TRANSACTION: 'transactions',
  EXPENSE: 'expenses',
  BALANCE: 'balance',
  PERSON: 'person',
  ACCOUNT: 'account'
}

export const MONTHS_SELECT = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10',
  11: '11',
  12: '12'
}

export const ROLE = {
  MASTER: 'MASTER',
  USER: 'USER',
  VIEWER: 'VIEWER',
}