import React from 'react'
import CustomTypography from '../../components/commons/customs/CustomTypography'
import LastBalanceView from './LastBalanceView'
import OrgBalabceHistoryView from './OrgBalabceHistoryView'

const OrgBalanceView = () => {
  return (
    <>
      <CustomTypography text={'dashBoard.right.orgBalance'} variant={'h3'}/>
      <LastBalanceView />
      <OrgBalabceHistoryView />
    </>
  )
}

export default OrgBalanceView