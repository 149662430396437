import React from 'react'
import Button from '@mui/material/Button'
import { usedColor } from '../../../theme/palette'
import { Stack, styled, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useRouter } from '../../../routes/useRoutes'


const IconButton = ({ item }) => {
  const { t }  = useTranslation()
  const appRouter = useRouter()

  const StyledButton = styled(Button)`
      color: ${usedColor.primary.main};
      border: 1px solid ${usedColor.primary.main};
      background-color: ${usedColor.common['1']};
      border-radius: 10px;
      width: 120px;
      height: 120px;
      font-size: 40px;
      :hover {
          border: 1px solid ${usedColor.secondary.main};
          color: ${usedColor.secondary.main};
          background-color: ${usedColor.primary.main};
          
      }
  `

  const StyledTypo = styled(Typography)`
        font-weight: normal;
        font-size: 14px;
    `
  const handleClick = () => {
    appRouter.push(item?.path)
  }
  return (
    <div>
      <StyledButton onClick={handleClick} sx={{ textAlign: 'center' }}>
        <Stack
          color="inherit"
          justifyContent={'center'}
          justifyItems={'center'}>
          <div style={{ marginBottom: '-20px' }}>{item?.icon}</div>
          <StyledTypo color='inherit' key={item?.id + 'item'}>{t('dashBoard.right.' + item?.id)}</StyledTypo>
        </Stack>
      </StyledButton>
    </div>
  )
}

export default IconButton