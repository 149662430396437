import React from 'react'
import TestGrid from '../../../theme/TestGrid'
import Box from '@mui/material/Box'
import { IOSSwitch } from '../../StyledComponents/utils/selectComponentObject'

const StyledSwitch = ({ props, data }) => {
  return (
    <TestGrid>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }} >
        <IOSSwitch
          id={props?.fieldName}
          name={props?.fieldName}
          checked={data?.values[props?.fieldName]}
          onChange={data?.handleChange}
        />
      </Box>
    </TestGrid>

  )
}

export default StyledSwitch