import React from 'react'
import { Card, CardContent, styled, Typography } from '@mui/material'
import { usedColor } from '../../../theme/palette'

const CustomCard = ({ props, isClicked }) => {
  const { list, icon, borderColor } = props
  const StyledCard = styled(Card)`
    cursor: pointer;
    color: ${usedColor.primary.main};
    min-width: 360px;
    border: 2px solid ${borderColor ? borderColor : usedColor.primary.main};
    background-color: ${usedColor.common['1']};
    border-radius: 10px;
    font-size: 46px;
    margin-bottom: 10px;
    padding: 1px;
      :hover {
          border: 2px solid ${usedColor.secondary.main};
          color: ${usedColor.common['1']};
          background-color: ${usedColor.primary.main};

      }
  `
  return (
    <StyledCard sx={{ display: 'flex' }} onClick={isClicked}>
      <CardContent sx={{ flex: '1 0 auto' }}>
        {
          list?.map((j, i) => <Typography key={i} variant="subtitle2" >{j}</Typography>)
        }
      </CardContent>
      <>{icon && icon}</>
    </StyledCard>
  )
}

export default CustomCard