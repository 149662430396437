import React, { createContext, useContext, useEffect, useMemo, useRef } from 'react'
import { Grid } from '@mui/material'
import { useFormik } from 'formik'
import Button from '@mui/material/Button'
import TypographyApp from '../commons/TypographyApp'
import StyledTextField from './components/StyledTextField'
import Box from '@mui/material/Box'
import StyledSelectTextField from './components/StyledSelectTextField'
import StyledDatePicker from './components/StyledDatePicker'
import { useTranslation } from 'react-i18next'
import { useRouter } from '../../routes/useRoutes'
import CustomButton from '../commons/customs/CustomButton'
import StyledSwitch from './components/StyledSwitch'


const FormikContext = createContext({})

const FormikDataForm = (props) => {
  const { handleSubmit, label, initData, submitSchema, } = props
  const targetDivRef = useRef(null)

  const formik = useFormik({
    initialValues: initData,
    validationSchema: submitSchema,
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })

  useEffect(() => {
    formik.setValues(initData)
    if (targetDivRef.current) {
      targetDivRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  // eslint-disable-next-line
  }, [initData])
  // eslint-disable-next-line
  const externalData =  useMemo(() => ({ formik }),[formik, initData])

  return (
    <FormikContext.Provider value={externalData}>
      {label && <TypographyApp variant={'h3'} str={label} marginBottom={3}/>}
      <div ref={targetDivRef}></div>
      <Box component={'form'}>
        {props.children}
      </Box>
    </FormikContext.Provider>
  )
}

const FormComponentWrapper = (props) => {
  const { Component, ...other } = props
  return (
    <Box
      sx={{ display: 'flex', flexWrap: 'wrap' }}
      style={{ marginBottom: '2rem' }}>
      <Grid container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        direction="row"
        justifyContent="flex-start"
        alignItems="center">
        <Component {...other}/>
      </Grid>
    </Box>
  )
}
const CommonButtonWrapper = (props) => {
  const { Component, ...other }= props
  return(
    <Component {...other} />
  )
}


const InputFormik = (props) => {
  const { formik } = useContext(FormikContext)
  return (<StyledTextField data={formik} props={props}/>)
}

const SelectFormik = (props) => {
  const { formik } = useContext(FormikContext)
  return (<StyledSelectTextField data={formik} props={props}/>)
}

const SwitchFormik = (props) => {
  const { formik } = useContext(FormikContext)
  return (<StyledSwitch data={formik} props={props}/>)
}

const DateFormik = (props) => {
  const { formik } = useContext(FormikContext)
  return (<StyledDatePicker data={formik} props={props} />)
}
const SubmitButtonFormik = (props) => {
  const { t } = useTranslation()
  const { formik } = useContext(FormikContext)
  const { label } = props
  return (
    <Button variant={'usedColor'} size={'large'} onClick={formik.handleSubmit} key={label} disabled={props.disabled}>
      {t(label)}
    </Button>
  )
}
const CommonButton = (props) => {
  const { t } = useTranslation()
  const { label, onClick } = props
  return (
    <Button variant={'usedColor'} size={'large'} onClick={onClick} key={label} disabled={props.disabled}>
      {t(label)}
    </Button>
  )
}
const BackButtonFormik = () => {
  const route = useRouter()
  return (
    <CustomButton text={'commons.back'} handleClick={() => route.back()} />
  )
}


const Input = (props) => <FormComponentWrapper {...props} Component={InputFormik} />
const Select = (props) => <FormComponentWrapper {...props} Component={SelectFormik} />
const DatePicker = (props) => <FormComponentWrapper {...props} Component={DateFormik} />
const Check = (props) => <FormComponentWrapper {...props} Component={SwitchFormik} />
const SubmitButton = (props) => <CommonButtonWrapper {...props} Component={SubmitButtonFormik}/>
const FormButton = (props) => <CommonButtonWrapper {...props} Component={CommonButton}/>
const BackButton = (props) => <CommonButtonWrapper {...props} Component={BackButtonFormik}/>


FormikDataForm.Input = Input
FormikDataForm.Select = Select
FormikDataForm.DatePicker = DatePicker
FormikDataForm.Check = Check
FormikDataForm.SubmitButton = SubmitButton
FormikDataForm.FormButton = FormButton
FormikDataForm.BackButton = BackButton
export default FormikDataForm