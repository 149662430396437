import React from 'react'
import TextField from '@mui/material/TextField'
import { InputAdornment, MenuItem, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import TestGrid from '../../../theme/TestGrid'
import { EXPENSES_CATEGORY, MONTHS_SELECT, STATEMENT_TYPE, TRANSACTION_DESCRIPTION } from '../../../utils/Enums'
import { yearObjectsArray } from '../../../utils/methods'

const StyledSelectTextField = ({ data, props }) => {
  const { t } = useTranslation()

  return (
    <TestGrid>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }} >
        <TextField
          fullWidth
          select
          disabled={props.disabled}
          id={props.fieldName}
          name={props.fieldName}
          value={data.values[props.fieldName]}
          onChange={data.handleChange}
          onBlur={data.handleBlur}
          error={data.touched[props.fieldName] && Boolean(data.errors[props.fieldName])}
          helperText={data.touched[props.fieldName] && t(data?.errors[props?.fieldName])}
          InputProps={{
            startAdornment: <InputAdornment position="start" sx={{ marginLeft: '1rem' }}>
              <Typography variant='h6' sx={{ borderLeft: '1px dashed', minWidth: '135px' }}>{t(props.label)}</Typography>
            </InputAdornment>,
          }}
          SelectProps={{
            IconComponent: () => <></>
          }}>
          {
            selectData[props.fieldName].map((item) => (
              <MenuItem
                key={item.name}
                value={item.value}>
                { props.showOnlyValue ? item.value : t(`select.${props.fieldName}.${item.value}`)}
              </MenuItem>
            ))
          }
        </TextField>
      </Box>
    </TestGrid>
  )
}

export default StyledSelectTextField

const selectData = {
  gender: [
    { name: 'Male', value: 'Male' },
    { name: 'Female', value: 'Female' },
  ],
  status: [
    { name: 'married', value: 'married' },
    { name: 'single', value: 'single' },
    { name: 'widow', value: 'widow' },
    { name: 'divorce', value: 'divorce' },
  ],
  familyCount:[
    { name: '1', value: '1' },
    { name: '2', value: '2' },
    { name: '3', value: '3' },
    { name: '4', value: '4' },
    { name: '5', value: '5' },
    { name: '6', value: '6' },
    { name: '7', value: '7' },
    { name: '8', value: '8' },
    { name: '9', value: '9' },
    { name: '10', value: '10' },
  ],
  type: [
    { name: 'CASH', value: 'CASH' },
    { name: 'BANK', value: 'BANK' },
  ],
  statementType: [
    { name: STATEMENT_TYPE.MONTH_FEE, value: STATEMENT_TYPE.MONTH_FEE },
    { name: STATEMENT_TYPE.OTHER_FEE, value: STATEMENT_TYPE.OTHER_FEE },
  ],
  description: [
    { name: TRANSACTION_DESCRIPTION.MONTH_FEE, value: TRANSACTION_DESCRIPTION.MONTH_FEE },
    { name: TRANSACTION_DESCRIPTION.DONATION, value: TRANSACTION_DESCRIPTION.DONATION },
  ],
  category: Object.keys(EXPENSES_CATEGORY).map(key => ({
    name: EXPENSES_CATEGORY[key],
    value: EXPENSES_CATEGORY[key],
  })),

  month: Object.keys(MONTHS_SELECT).map(key => ({
    name: MONTHS_SELECT[key],
    value: MONTHS_SELECT[key],
  })),
  year: yearObjectsArray,
}