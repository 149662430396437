import { PATCH, POST } from './api'

export const createAccount = async (data) => {
  return await POST('api/accounts', data)
}


export const account = {
  create: async (data) => await POST('api/accounts', data),
  updateActive: async (id, data) => await PATCH( `api/accounts/${id}`, data)

}