import * as Yup from 'yup'
import { STATEMENT_TYPE } from '../../../utils/Enums'

const statementSchema = Yup.object({
  month: Yup.string()
    .required('errors.statement.date.required'),
  year: Yup.string()
    .required('errors.statement.date.required'),
  fee: Yup.number()
    .typeError('errors.statement.fee.typeError')
    .required('errors.statement.fee.required')
    .positive('errors.statement.fee.positive')
    .integer('errors.statement.fee.integer')
    .min(1, 'errors.statement.fee.tooSmall')
    .max(100, 'errors.statement.fee.tooLarge'),
  statementType: Yup.string()
    .required('errors.statement.statementType.required')
    .test('type', 'errors.statement.statementType.wrong', (value) => {
      return [STATEMENT_TYPE.MONTH_FEE, STATEMENT_TYPE.OTHER_FEE].includes(value)
    }),
  description: Yup.string()
})

export default statementSchema