export const tableColumns = {
  statement: [
    { id: 'isCheckedComponent',
      label: 'statement.table.isChecked',
      width: 90
    },

    {
      id: 'formattedmonth',
      label: 'statement.table.month',
      width: 90,
    },
    {
      id: 'year',
      label: 'statement.table.year',
      width: 90,
    },
    {
      id: 'formattedType',
      label: 'statement.table.statementType',
      width: 90,
    },
    {
      id: 'feeComponent',
      label: 'statement.table.fee',
      width: 90,
    },
    { id: 'timestamp',
      label: 'statement.table.timestamp',
      width: 90
    },
  ],
  transactions: [
    { id: 'isCheckedComponent',
      label: 'transaction.table.isChecked',
      width: 50
    },
    {
      id: 'person',
      label: 'transaction.table.person',
      width: 50,
    },
    {
      id: 'amountEuro',
      label: 'transaction.table.amount',
      width: 50,
    },
    {
      id: 'descriptionFormatted',
      label: 'transaction.table.description',
      width: 50,
    },
    { id: 'timestamp',
      label: 'transaction.table.timestamp',
      width: 50
    },
  ],
  expenses: [
    {
      id: 'isCheckedComponent',
      label: 'expense.table.isChecked',
      width: 50,
    },{
      id: 'categoryFormatted',
      label: 'expense.table.category',
      width: 50,
    },
    {
      id: 'amountEuro',
      label: 'expense.table.amount',
      width: 50,
    },
    { id: 'createdAt',
      label: 'expense.table.createdAt',
      width: 50
    },
  ],
  balance: [
    {
      id: 'balance',
      label: 'balance.table.balance',
      width: 100,
      minWidth: 100
    },
    {
      id: 'deposited',
      label: 'balance.table.deposited',
      width: 100,
      minWidth: 100
    },
    {
      id: 'notDeposited',
      label: 'balance.table.notDeposited',
      width: 100,
      minWidth: 100
    },
    {
      id: 'typeFormatted',
      label: 'balance.table.type',
      width: 50,
    },
    {
      id: 'sequenceNumber',
      label: 'balance.table.sequenceNumber',
      width: 50,
    },
  ]
}