import React from 'react'
import { Avatar, Card, CardContent, styled } from '@mui/material'
import { usedColor } from '../../../theme/palette'
import TextField from '@mui/material/TextField'

const Developer = () => {
  const StyledCard = styled(Card)`
    color: ${usedColor.primary.main};
    display: flex;
    border-radius: 10px;
    font-size: 30px;
    width: inherit;
    margin-bottom: 10px;
    padding: 1px;
    box-shadow: none;
  `
  return (
    <StyledCard>
      <Avatar
        sx={{
          width: 80,
          height: 80,
          margin: '10px',
        }}
        alt={'moha'}
        src={'./images/moha.jpg'}
      />
      <CardContent>
        <TextField
          fullWidth
          multiline={true}
          variant="standard"
          disabled={true}
          id={'info'}
          InputProps={{ disableUnderline: true, style: { fontSize: '12px', minWidth: '200px', marginTop: '10px' } }}
          name={'info'}
          defaultValue={' البرنامج من تصميم وتنفيذ وبرمجة المهندس محمد عبد الامير شاكر التويح'}
        />
        {/* <CustomTypography type={'7'} text={'mohamid.shaker@hotmail.com'} />*/}
      </CardContent>

    </StyledCard>
  )
}

export default Developer