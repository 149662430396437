import React from 'react'
import { Card, CardContent, styled } from '@mui/material'
import { usedColor } from '../../../theme/palette'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import CustomTypography from '../../../components/commons/customs/CustomTypography'
import StyledNumber from '../../../components/commons/customs/StyledNumber'
import TextField from '@mui/material/TextField'
import { getDate } from '../../../utils/methods'

const ExpenseCard = ({ expense }) => {
  const StyledCard = styled(Card)`
    color: ${usedColor.primary.main};
    width: 100%;
    border-radius: 10px;
    font-size: 46px;
    margin-bottom: 10px;
    padding: 1px;
  `

  return (
    <StyledCard sx={{ display: 'flex' }}>
      <CardContent sx={{ flex: '1 0 auto' }}>
        <CustomTypography text={`select.category.${expense?.category}`} type={'1'}/>
        <StyledNumber number={expense?.amount} type={'text'} fontSize={'1rem'}/>
        <TextField
          fullWidth
          multiline={true}
          variant="standard"
          disabled={true}
          id={'description'}
          name={'descriptions'}
          InputProps={{ disableUnderline: true }}
          defaultValue={expense?.description}
        />
        <CustomTypography text={`${getDate(expense?.createdAt)}`} type={'5'}/>

      </CardContent>
      <>{expense?.isChecked ?
        <CheckCircleIcon sx={{ color: usedColor.success.dark, margin: 2, marginTop: '5%', fontSize: 'inherit' }}/> :
        <RemoveCircleIcon sx={{ color: usedColor.error.dark, margin: 2, marginTop: '5%', fontSize: 'inherit', }}/>}</>
    </StyledCard>
  )
}

export default ExpenseCard