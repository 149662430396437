import React from 'react'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material'
import { usedColor } from '../../../theme/palette'

const StyledIconButton = ({ text, handleClick, disable, icon }) => {
  const { t } = useTranslation()

  const StyledIcon = styled(Button)`
    color: ${usedColor.primary.main};
    border-radius: 5px;
    font-size: 18px;
    margin: 1rem;
    border: 1px solid ${usedColor.primary.main};
    :hover {
      border: 1px solid ${usedColor.secondary.main};
      color: ${usedColor.secondary.main};
    }
    // eslint-disable-next-line
    & .MuiSvgIcon-root {
      margin-right: 20px;
      margin-bottom: 10px;
      margin-top: 10px;
      color: inherit;
      font-size: ${'30px'};
      :hover {
        color: inherit;
      }
    }
  `
  return (
    <StyledIcon
      variant={'usedColor'}
      size={'large'}
      onClick={handleClick}
      key={text}
      endIcon={icon}
      disabled={disable}>
      {t(text)}
    </StyledIcon>
  )
}

export default StyledIconButton