import * as i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import arabic from './ar/ar.json'
import finnish from './fi/fi.json'


const resources = {
  fi: {
    translation: finnish
  },
  ar:{
    translation: arabic
  }
}
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'ar', // if you're using a language detector, do not define the lng option
    fallbackLng: 'ar',

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  }).catch(e => console.log(e))

export default i18n