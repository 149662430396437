import React from 'react'
import { IconButton, styled } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { grey, usedColor } from '../../theme/palette'
import ItemText from './ItemText'

const DrawerItem = ({ page, windowSize }) => {
  const location = useLocation()
  const StyledLink = styled(Link)`
    color: ${usedColor.common['1']};
  `
  const StyledIcon = styled(IconButton)`
    color: ${grey['0']};
    border-radius: 5px;
    margin: 0;
    border: 1px solid ${location?.pathname === page.path ?  usedColor.secondary.main: usedColor.primary.main};
    color: ${location?.pathname === page.path ?  usedColor.secondary.main: usedColor.common['1']};
    :hover {
      border: 1px solid ${usedColor.secondary.main};
      color: ${usedColor.secondary.main};
    }
    // eslint-disable-next-line
    & .MuiSvgIcon-root {
      color: inherit;
      font-size: ${windowSize.isDownMd ? '25px' : '30px'};
      :hover {
        color: inherit;
      }
    }
  `
  return (
    <StyledIcon
      aria-label={page.id}
      key={page.id}
      component={StyledLink}
      sx={{ flexDirection: 'column', fontSize: 10  }}
      to={page.path} >

      {page.icon}
      <ItemText text={'dashBoard.right.' + page.id} key={page.id + 'item'} />
    </StyledIcon>
  )
}

export default DrawerItem