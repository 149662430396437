import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import StorageProvider from './components/providers/StorageProvider'
import { HashRouter } from 'react-router-dom'
import App from './App'

const Main = () => {

  return (
    <>
      <HelmetProvider>
        <HashRouter>
          <StorageProvider>
            <App />
          </StorageProvider>
        </HashRouter>
      </HelmetProvider>

    </>
  )
}

export default Main