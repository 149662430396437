import React from 'react'
import expenseSchema from '../schemas/expenseSchema'
import FormikDataForm from '../FormikDataForm'


const intiExpense = {
  amount: '',
  category: '',
  description: '',
}
const ExpenseForm = ({ close, onSubmit }) => {
  const handleSubmit = (data) => {
    onSubmit(data)
  }
  return (
    <>
      <FormikDataForm
        initData={intiExpense}
        label={'expense.form.header'}
        submitSchema={expenseSchema}
        handleSubmit={handleSubmit}>
        <FormikDataForm.Input label={'expense.form.amount'} fieldName={'amount'} type={'number'}/>
        <FormikDataForm.Select label={'expense.form.category'} fieldName={'category'}/>
        <FormikDataForm.Input label={'expense.form.description'} fieldName={'description'} />
        <FormikDataForm.SubmitButton label={'expense.form.save'}  />
        <FormikDataForm.FormButton label={'commons.close'} onClick={() => close()} />
      </FormikDataForm>
    </>

  )
}

export default ExpenseForm