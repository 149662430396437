import * as Yup from 'yup'
import { isEmpty } from 'lodash'


export const appSchema = {
  notRequiredStringMax: (n) => Yup.string().max(n, `errors.common.tooLarge${n}`),
  notRequirdMaxNumber: (i,x) => Yup.number()
    .typeError('errors.common.typeError')
    .positive('errors.common.positive')
    .integer('errors.common.integer')
    .min(i, `errors.common.tooSmall${i}`)
    .max(x, `errors.common.tooLargeNumber${x}`),
  requirdMaxNumber: (i,x) => Yup.number()
    .required('errors.common.required')
    .typeError('errors.common.typeError')
    .positive('errors.common.positive')
    .integer('errors.common.integer')
    .min(i, `errors.common.tooSmall${i}`)
    .max(x, `errors.common.tooLargeNumber${x}`),
  requiredSelect: (id, objArray) => Yup.string()
    .required(`errors.common.required.${id}`)
    .test(id, `errors.common.required.${id}`, (value) => {
      return Object.values(objArray).includes(value)
    }),
  requiredStringMinMax: (i,x, field) => Yup.string()
    .min(i, `errors.${field}.tooShort`)
    .max(x, `errors.${field}.tooLong`)
    .required(`errors.${field}.required`),
  requiredNumberMinMax: (i,x, field) => Yup.string()
    .min(i, `errors.${field}.tooShort`)
    .max(x, `errors.${field}.tooLong`)
    .required(`errors.${field}.required`),
  requiredEmail: (field) =>  Yup.string()
    .email('errors.common.email')
    .required(`errors.${field}.required`),
  requiredPassword: (field) => Yup.string()
    .required(`errors.${field}.required`)
    .test(
      field,
      'Password must be strong (min 8 characters, uppercase, lowercase, digit, special character)',
      (value) => {
        if (value.length < 8) {
          return false
        }
        const hasUppercase = /[A-Z]/.test(value)
        const hasLowercase = /[a-z]/.test(value)
        const hasDigit = /\d/.test(value)

        return hasUppercase && hasLowercase && hasDigit
      }
    ),
  notRequiredPassword: (field) => Yup.string()
    .test(
      field,
      'Password must be strong (min 8 characters, uppercase, lowercase, digit, special character)',
      (value) => {
        if (isEmpty(value)) {
          return true
        }
        if (value.length < 8) {
          return false
        }
        const hasUppercase = /[A-Z]/.test(value)
        const hasLowercase = /[a-z]/.test(value)
        const hasDigit = /\d/.test(value)

        return hasUppercase && hasLowercase && hasDigit
      }
    ),
}