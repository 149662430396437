import React from 'react'
import Box from '@mui/material/Box'
import TestGrid from '../../../theme/TestGrid'
import TextField from '@mui/material/TextField'
import { InputAdornment, MenuItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { selectData } from '../../StyledComponents/utils/selectComponentObject'

const CustomSelect = ({ fieldName, defaultValue, handleChange, showOnlyValue = false, label }) => {
  const { t } = useTranslation()
  return (
    <TestGrid>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }} >
        <TextField
          fullWidth
          select
          id={fieldName}
          name={fieldName}
          defaultValue={defaultValue}
          onChange={handleChange}
          InputProps={{
            startAdornment: <InputAdornment position="start" sx={{ marginLeft: '1rem' }}>
              <Typography variant='h6' sx={{ borderLeft: '1px dashed', minWidth: '135px' }}>{t(label)}</Typography>
            </InputAdornment>,
          }}
          SelectProps={{
            IconComponent: () => <></>
          }}>
          {
            selectData[fieldName].map((item) => (
              <MenuItem
                key={item.name}
                value={item.value}>
                { showOnlyValue ? item.value : t(`select.${fieldName}.${item.value}`)}
              </MenuItem>
            ))
          }
        </TextField>
      </Box>
    </TestGrid>
  )
}

export default CustomSelect