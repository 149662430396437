import React from 'react'
import CustomGrid from '../../../theme/TestGrid'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { InputAdornment, styled, Typography } from '@mui/material'
import { customDay } from '../../../theme/palette'

const LabeledCurrency = ({ id, value, label, fontSize, textAlign }) => {

  const typoObject = {
    success: `${customDay.success}`,
    error: `${customDay.error}`,
    warning: `${customDay.warning}`,
    text: `${customDay.primary}`,
  }


  const CustomizedTextField = styled(TextField)`
   // eslint-disable-next-line
    & .MuiInputBase-input.Mui-disabled { 
        border-radius: 10px;
        color: ${typoObject[value === 0 ? 'warning' : (value > 0 ? 'success' : 'error')]};
        -webkit-text-fill-color: ${typoObject[value === 0 ? 'warning' : (value > 0 ? 'success' : 'error')]};
        font-size: ${fontSize};
        direction: ltr;
        text-align: ${textAlign};
    }
      .
  `
  return (
    <CustomGrid>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }} >
        <CustomizedTextField
          fullWidth
          disabled={true}
          id={id}
          name={id}
          value={value + ' €'}
          InputProps={{
            startAdornment: <InputAdornment position="start" sx={{ marginLeft: '1rem' }}>
              <Typography variant='h6' sx={{ borderLeft: '1px dashed',  minWidth: '120px' }}>{label}</Typography>
            </InputAdornment>, }
          }
          multiline={true}
        />
      </Box>
    </CustomGrid>
  )
}

export default LabeledCurrency