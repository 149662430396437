import React from 'react'
import TextField from '@mui/material/TextField'
import { InputAdornment, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import CustomGrid from '../../../theme/TestGrid'

const StyledTextField = ({ data, props }) => {
  const { t } = useTranslation()

  return (
    <CustomGrid>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }} >
        <TextField
          fullWidth
          type={props.type ? props.type : 'text'}
          disabled={props.disabled}
          id={props?.fieldName}
          name={props?.fieldName}
          value={data?.values[props?.fieldName]}
          onChange={data?.handleChange}
          onBlur={data?.handleBlur}
          error={data?.touched[props?.fieldName] && Boolean(data?.errors[props?.fieldName])}
          helperText={data?.touched[props?.fieldName] && t(data?.errors[props?.fieldName])}
          InputProps={{
            startAdornment: <InputAdornment position="start" sx={{ marginLeft: '1rem' }}>
              <Typography variant='h6' sx={{ borderLeft: '1px dashed',  minWidth: '120px' }}>{t(props?.label)}</Typography>
            </InputAdornment>,
          }}
          multiline={true}
        />
      </Box>
    </CustomGrid>
  )
}

export default StyledTextField