import React from 'react'
import { InputAdornment, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'

const CustomTextField = ({ fieldaname, value, label, onchange }) => {
  const { t } = useTranslation()
  return (
    <TextField
      id={fieldaname}
      name={fieldaname}
      value={value}
      onChange={(e) => onchange(e.target.value)}
      InputProps={{
        startAdornment: <InputAdornment position="start" sx={{ marginLeft: '1rem' }}>
          <Typography variant='h6' sx={{ borderLeft: '1px dashed',  minWidth: '120px' }}>{t(label)}</Typography>
        </InputAdornment>,
      }}
      multiline={true}
    >

    </TextField>
  )
}

export default CustomTextField