import { DELETE, GET, PATCH, POST } from './api.js'
import { urls } from './urls'


export const getAll = async () => {
  return  await GET('api/persons')
}

export const getOne = async (id) => {
  return  await GET(`api/persons/${id}`)
}
export const createPerson = async (data) => {
  return await POST('api/persons', data)
}

export const modifyIPerson = async (id, data) => {
  return await PATCH('api/persons/' + id, data)
}

export const deletePerson = async (id) => {
  return await DELETE('api/persons/' + id)
}


export const personsAPI = {
  getAllPersons: async (page, limit, search) => await GET(urls.persons.base + `?search=${search}&page=${page}&limit=${limit}`),
  getOnePerson: async (personId) => await GET(urls.persons.base + `/${personId}`),
  createPerson: async (personData) => await POST(urls.persons.base, personData),
  modifyIPerson: async (personId, personData) => await PATCH(urls.persons.base + `/${personId}`, personData),
  deletePerson: async (personId) => await DELETE(urls.persons.base + `/${personId}`)
}

export const UnAuthAPI = {
  getAllPersons: async (page, limit, encodedSearchTerm) => await GET(urls.unAuth.persons + `?firstname=${encodedSearchTerm}&page=${page}&limit=${limit}`),
  getLastBalance: async () => await GET(urls.unAuth.lastBalance),
  getMonthExpenses: async (month) => await GET(urls.unAuth.expenses + `/${month}`),
  getDonations: async (month, year, page, limit) => await GET(urls.unAuth.donations + `?month=${month}&year=${year}&page=${page}&limit=${limit}`),
  getUsers: async () => await GET(urls.unAuth.members),
}