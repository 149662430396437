import React, { useMemo } from 'react'
import FormikDataForm from '../FormikDataForm'
import { updateUserSchema, userSchema } from '../schemas/userSchema'

const intiUser = {
  name: '',
  username: '',
  password: '',
  email: '',
  image: '',
  info: '',
  isActive: false,
}

const UserForm = ({ close, onSubmit, user }) => {

  const data = useMemo(() => {
    return user ? user : intiUser
  }, [user])



  const handleSubmit = (data) => {
    onSubmit(data)
  }
  return (
    <FormikDataForm
      initData={data}
      label={'user.form.header'}
      submitSchema={user ? updateUserSchema : userSchema}
      handleSubmit={handleSubmit}>
      <FormikDataForm.Input label={'user.form.name'} fieldName={'name'}/>
      <FormikDataForm.Input label={'user.form.username'} fieldName={'username'}/>
      <FormikDataForm.Input label={'user.form.password'} fieldName={'password'}/>
      <FormikDataForm.Input label={'user.form.email'} fieldName={'email'}/>
      <FormikDataForm.Input label={'user.form.image'} fieldName={'image'}/>
      <FormikDataForm.Input label={'user.form.info'} fieldName={'info'}/>
      <FormikDataForm.Check label={'user.form.isActive'} fieldName={'isActive'}/>
      <FormikDataForm.SubmitButton label={'user.form.save'}  />
      <FormikDataForm.FormButton label={'commons.close'} onClick={() => close()} />
    </FormikDataForm>
  )
}

export default UserForm