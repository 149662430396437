import React from 'react'
import { styled } from '@mui/material'

const BackGroundWrapper = ({ children, color, image }) => {
  const ImageWrapper = styled('div')({
    width: '100%',
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'round',
    //backgroundPosition: 'center',
    margin: 0, // Remove default margin for the body
    padding: 0, // Remove default padding for the body
    height: '100vh', // Set height to full viewport height
    marginBottom: '3rem'
  })

  const ColorWrapper = styled('div')({
    width: '100%',
    backgroundColor: color, // Set your desired background color for the body
    margin: 0, // Remove default margin for the body
    padding: 0, // Remove default padding for the body
    height: '90vh', // Set height to full viewport height
  })
  return (
    image ?
      <ImageWrapper>
        {children}
      </ImageWrapper> :
      <ColorWrapper>
        {children}
      </ColorWrapper>

  )
}

export default BackGroundWrapper