import StyledNumber from '../components/commons/customs/StyledNumber'
import { getDate } from '../utils/methods'
import DoneIcon from '@mui/icons-material/Done'
import { customDay } from '../theme/palette'
import CloseIcon from '@mui/icons-material/Close'
import React from 'react'

export const tableFormatRowCell = {
  statement: (statement, t) => ({
    ['id']: statement.id,
    ['year']: statement.year,
    ['month']: statement.month,
    ['formattedmonth']: t(`select.arabicMonths.${statement.month}`),
    ['statementType']: statement.statementType,
    ['formattedType']: t(`select.statementType.${statement.statementType}`),
    ['fee']: statement.fee,
    ['feeComponent']: <StyledNumber number={statement?.fee} fontSize={'1rem'} type={'text'}/>,
    ['description']: statement.description,
    ['timestamp']: getDate(statement?.timestamp),
    ['isChecked']: statement.isChecked,
    ['isCheckedComponent']: statement?.isChecked ? <DoneIcon sx={{ color: customDay.success }} /> : <CloseIcon sx={{ color: customDay.error }} />,
  }),
  transactions: (transaction, t) => ({
    ['id']: transaction?.id,
    ['personId']: transaction?.person.id,
    ['person']: `${transaction?.person?.firstname || ''} ${transaction?.person?.surname || ''}`,
    ['amount']: `${transaction?.amount} €`,
    ['amountEuro']: <StyledNumber number={transaction?.amount} fontSize={'1rem'} type={'text'}/>,
    ['method']: t(`transaction.table.paymentMethods.${transaction?.paymentMethod}`),
    ['type']: t(`transaction.table.transactionTypes.${transaction?.transactionType}`),
    ['timestamp']: getDate(transaction?.timestamp),
    ['isChecked']: transaction?.isChecked,
    ['descriptionFormatted']: t(`transaction.table.descriptionSelect.${transaction?.description}`),
    ['isCheckedComponent']: transaction?.isChecked ? <DoneIcon sx={{ color: customDay.success }} /> : <CloseIcon sx={{ color: customDay.error }} />,

  }),
  expenses: (expense, t) => ({
    ['id']: expense?.id,
    ['user']: `${expense?.user?.name}`,
    ['amount']: `${expense?.amount}`,
    ['amountEuro']: <StyledNumber number={Math.abs(expense?.amount)} fontSize={'1rem'} type={'text'}/>,
    ['createdAt']: getDate(expense?.createdAt),
    ['categoryFormatted']: t(`select.category.${expense?.category}`),
    ['isCheckedComponent']: expense?.isChecked ? <DoneIcon sx={{ color: customDay.success }} /> : <CloseIcon sx={{ color: customDay.error }} />,

  }),
  balance: (orgBalance, t) => ({
    ['id']: orgBalance.id,
    ['sequenceNumber']: orgBalance.sequenceNumber,
    ['balance']: <StyledNumber number={orgBalance.balance}/>,
    ['deposited']: <StyledNumber number={orgBalance.deposited}/>,
    ['notDeposited']: <StyledNumber number={-1*orgBalance.notDeposited}/>,
    ['typeFormatted']: t(`select.type.${orgBalance.type}`),
    ['type']: orgBalance.type,
  }),
  person: (personData, t) => ({
    ['fullName']: `${personData?.firstname || ''} ${personData?.surname || ''}`,
    ['birth']: `${personData?.birthCity || ''} ${getDate(personData?.dateOfBirth, 'year') || ''}`,
    ['status']:  t(`person.form.${personData?.gender+personData?.status}`),
    ['familyCount']: personData?.familyCount,
    ['birthCity']: personData?.birthCity,
    ['serialnumber']: personData?.serialnumber,
    ['account']: personData?.account,
    ['phone']: personData?.phone,
    ['phoneConfirmed']: personData?.phoneConfirmed,
  }),
  account: (account) => ({
    ['balanceFormatted']: <StyledNumber number={account?.balance} type={'text'} textAlign = 'right' fontSize={'1.3rem'}/>,
    ['id']: account?.id,
    ['balance']: account?.balance,
    ['openedAt']: getDate(account?.openedAt),
    ['isActive']: account?.isActive,
    ['accountNumber']: account?.accountNumber,


  })
}