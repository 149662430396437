import React from 'react'
import TestGrid from '../../../theme/TestGrid'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { DateField, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { InputAdornment, Typography } from '@mui/material'

const StyledDatePicker = ({ props, data }) => {
  const { t } = useTranslation()

  return (
    <TestGrid>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }} >
        <LocalizationProvider dateAdapter={AdapterDayjs} >
          <DateField
            fullWidth
            disabled={props.disabled}
            id={props?.fieldName}
            name={props?.fieldName}
            value={data?.values[props?.fieldName] ? dayjs(data?.values[props?.fieldName]) : null }
            format="YYYY"
            onChange={(date) => data?.setFieldValue(props?.fieldName, dayjs(date))}
            //onBlur={(date) => data?.setFieldValue(props?.fieldName, getDate(date))}
            error={data?.touched[props?.fieldName] && Boolean(data?.errors[props?.fieldName])}
            helperText={data?.touched[props?.fieldName] && t(data?.errors[props?.fieldName])}
            multiline={true}
            InputProps={{
              startAdornment: <InputAdornment position="start" sx={{ marginLeft: '1rem' }}>
                <Typography variant='h6' sx={{ borderLeft: '1px dashed',  minWidth: '120px' }}>{t(props?.label)}</Typography>
              </InputAdornment>,
            }}
          />
        </LocalizationProvider>

      </Box>
    </TestGrid>
  )
}

export default StyledDatePicker