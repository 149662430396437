import * as Yup from 'yup'
import { EXPENSES_CATEGORY } from '../../../utils/Enums'
import { appSchema } from './schemaRoles'

const expenseSchema = Yup.object({
  amount: appSchema.requirdMaxNumber(1, 10000),
  description: appSchema.notRequiredStringMax(200),
  category: appSchema.requiredSelect('category', EXPENSES_CATEGORY)

})
export default expenseSchema