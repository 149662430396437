import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import ButtonGroupWithGrid from '../grid/ButtonGroupWithGrid'
import { useTranslation } from 'react-i18next'
import CustomButton from './CustomButton'
import { customGreen } from '../../../theme/palette'
import { useAppSetting } from '../../providers/AppSettingProvider'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 600,
  width: '50%',
  minWidth: 400,
  bgcolor: `${customGreen['300']}`,
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const CustomAppModal = ({ modalContent= { modalTitle: 'Title',modalContent: 'Content' }, arg1, chosenItem, closing, buttons }) => {
  const { t } = useTranslation()
  const { data } = useAppSetting()
  return (
    <Modal
      open={chosenItem !== null}
      onClose={() => closing()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} dir={data.language === 'ar' ? 'rtl' : 'ltr'}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {t(modalContent.modalTitle)}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {t(modalContent.modalContent, { arg1 })}
        </Typography>
        <ButtonGroupWithGrid>
          {
            buttons.map(button => button)
          }
          <CustomButton text={'commons.close'} handleClick={() => closing()} />
        </ButtonGroupWithGrid>
      </Box>
    </Modal>
  )
}

export default CustomAppModal
