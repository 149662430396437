import React, { useEffect, useState } from 'react'
import MemebrtCard from './MemebrtCard'
import { UnAuthAPI } from '../../../services/api/Persons'
import PersonCard from '../../users/PersonCard'
import CustomTypography from '../../../components/commons/customs/CustomTypography'
import Developer from './Developer'




const Index = () => {
  const [users, setUsers] = useState([])
  const getUsers = async() => {
    const data = await UnAuthAPI.getUsers()
    setUsers(data)
  }
  useEffect(() => {
    getUsers().catch(e => console.log(e))
  }, [])

  return (
    <div>
      <CustomTypography text={'publicText.members'} type={'1'} variant={'h4'}/>
      <PersonCard>{users?.map(data => (<MemebrtCard data={data} key={data.id}/>))}</PersonCard>
      <Developer />
    </div>
  )
}

export default Index