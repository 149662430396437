import React, { useEffect, useState } from 'react'
import { useRouter } from '../../routes/useRoutes'
import { useParams } from 'react-router-dom'
import { expenses } from '../../services/api/expenses'
import BackButton from '../../components/commons/BackButton'
import ButtonGroupWithGrid from '../../components/commons/grid/ButtonGroupWithGrid'
import { PATHS } from '../../utils/contants'
import CustomButton from '../../components/commons/customs/CustomButton'
import ReadOnlyForm from '../../components/StyledComponents/readableOnly/ReadOnlyForm'
import ProgressWrapper from '../../components/wrappers/ProgressWrapper'

const ExpenseInfo = () => {
  const router = useRouter()
  const { id } = useParams()
  const [expenseData, setExpenseData] = useState({})
  const [loading, setLoading] = React.useState(true)

  const fetchData = async () => {
    try {
      const data = await expenses.getOneExpense(id)
      setExpenseData(data)
    } catch (e) {
      console.error(e)
    }
  }
  useEffect( () => {
    fetchData().then(() => {
      setLoading(false)
    })
    // eslint-disable-next-line
  }, [id])


  const handleDelete = async () => {
    try {
      await expenses.deleteExpense(expenseData.id)
      router.push(`${PATHS.logged.expense}`)
    } catch (e) {
      console.error(e.message)
    }
  }

  return (
    <div>
      <ProgressWrapper load={loading}>
        <>
          <ReadOnlyForm data={expenseData} label={'expense.header'}>
            <ReadOnlyForm.Select id={'category'} label={'expense.form.category'} />
            <ReadOnlyForm.Input id={'amount'} label={'expense.form.amount'} />
            <ReadOnlyForm.Date id={'createdAt'} label={'expense.form.createdAt'} />
            <ReadOnlyForm.Input id={'user.name'} label={'expense.form.user'} />
            <ReadOnlyForm.Input id={'description'} label={'expense.form.description'} />
          </ReadOnlyForm>
          <ButtonGroupWithGrid>
            <BackButton />
            {!expenseData.isChecked && <CustomButton
              text={'expense.modal.delete'}
              handleClick={handleDelete}
              key={'deleteExpense'} />}
          </ButtonGroupWithGrid>
        </>
      </ProgressWrapper>
    </div>
  )
}

export default ExpenseInfo