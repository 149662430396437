import React from 'react'
import { styled, Typography } from '@mui/material'
import { customDay, customGreen, usedColor } from '../../../theme/palette'
import { useTranslation } from 'react-i18next'

const CustomTypography = ({ text, variant, type = '1' , hover = true }) => {
  const { t } = useTranslation()

  const title1 = () => {
    return styled(Typography)`
      color: ${usedColor.primary.main};
      font-weight: bold;
      margin-bottom: 1.5rem;
    `
  }
  const title2 = () => {
    return styled(Typography)`
      color: ${usedColor.primary.main};
      margin-top: 0.3rem;
      margin-right: 0;
      :hover {
        color:  ${hover ? customGreen['50'] : customDay.background2};
      }
    `
  }
  const title3 = () => {
    return styled(Typography)`
      color: ${usedColor.primary.main};
      font-weight: lighter;
      font-size: 8px;
      margin-top: 0.3rem;
      margin-right: 0;
      :hover {
        color:  ${customGreen['900']};
      }
    `
  }
  const title4 = () => {
    return styled(Typography)`
      color: ${usedColor.primary.main};
      font-weight: bold;
    `
  }

  const title5 = () => {
    return styled(Typography)`
      color: ${usedColor.primary.main};
      font-weight: inherit;
      font-size: 16px;
      margin-left: 1rem;
      
    `
  }

  const title6 = () => {
    return styled(Typography)`
      color: ${usedColor.primary.main};
      font-weight: bold;
      font-size: 17px;
      margin: 0;
      
    `
  }

  const title7 = () => {
    return styled(Typography)`
      color: ${usedColor.primary.main};
      font-weight: inherit;
      margin: 0;
    `
  }

  const typographyObject = {
    '1': title1(),
    '2': title2(),
    '3': title3(),
    '4': title4(),
    '5': title5(),
    '6': title6(),
    '7': title7(),
  }
  const TypoCustom = typographyObject[type]

  return (
    <TypoCustom component={'p'} variant={variant} key={'TypoCustom'+ Math.random().toString()}>
      {t(text)}
    </TypoCustom>
  )
}

export default CustomTypography