import * as Yup from 'yup'
import { appSchema } from './schemaRoles'

export const personValidationSchema = Yup.object().shape({
  firstname: appSchema.requiredStringMinMax(2, 20, 'person.firstname'),
  surname: appSchema.requiredStringMinMax(2, 20, 'person.surname'),
  gender: Yup.string().required('errors.person.gender.required'),
  status: Yup.string().required('errors.person.status.required'),
  familyCount: appSchema.requirdMaxNumber(0, 11,'person.familyCount'),
  dateOfBirth: Yup.string(),
  birthCountry: Yup.string().max(20, 'errors.person.birthCountry.tooLong'),
  birthCity: Yup.string().max(20, 'errors.person.birthCity.tooLong'),
  phone: Yup.string().max(15, 'errors.person.phone.tooLong').min(11, 'errors.person.phone.tooShort'),
})

export const personSchema = Yup.object().shape({
  firstname: Yup.string(),
  surname: Yup.string(),
  gender: Yup.string(),
  status: Yup.string(),
  familyCount: Yup.string(),
  dateOfBirth: Yup.string(),
  birthCountry: Yup.string(),
  birthCity: Yup.string(),
})
