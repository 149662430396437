import React from 'react'
import List from '@mui/material/List'
import CustomListItem from '../commons/CustomListItem'
import CustomTypography from '../../../../components/commons/customs/CustomTypography'

const Index = ({ fetchData }) => {

  return (
    <List sx={{ width: '100%', maxWidth: 600, bgcolor: 'background.paper' }}>
      {
        fetchData?.map((item, i) =>
          <CustomListItem
            key={'CustomListItem'+ i}
            primary={ <CustomTypography key={'prim' + Math.random().toString()} text={item?.fullName} type={'4'}/> }
            secondary={[
              <CustomTypography key={'family'+ Math.random().toString()} text={item?.family} type={'4'}/>,
              item?.balance,
              item?.isActive
            ]} />
        )

      }
    </List>)
}

export default Index