import axios from 'axios'
import { clearAllLocalStorage, getFromLocalStorage } from '../../utils/methods.js'


const baseUrl = {
  ['development']: 'http://localhost:8080',
  ['production']: '',
  ['undefined']: 'http://localhost:8080'
}
const api = axios.create({
  baseURL: baseUrl[process.env.NODE_ENV],
  headers: {
    'Content-Type': 'application/json',
  },
})

api.interceptors.request.use(
  (config) => {
    const token = getFromLocalStorage('USER')?.token
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)


const analizeError = (error) => {
  console.log('-------xxoxx-----api error---', error)
  if (error?.response.status === 401) {
    clearAllLocalStorage()
  }

}
const handleRequest = async (requestFunction, ...args) => {
  try {
    const response = await requestFunction(...args)
    return response.data
  } catch (error) {
    analizeError(error)
    throw error // Re-throw the error to propagate it further if necessary
  }
}
export const GET = async (url) =>   handleRequest(api.get, url)
export const POST = async (url, data) =>   handleRequest(api.post, url, data)
export const PUT = async (url, data) =>   handleRequest(api.put, url, data)
export const PATCH = async (url, data) =>   handleRequest(api.patch, url, data)
export const DELETE = async (url) =>   handleRequest(api.delete, url,)

