import React, { createContext, useContext, useMemo, useRef } from 'react'
import Box from '@mui/material/Box'
import { Grid } from '@mui/material'
import TypographyApp from '../../commons/TypographyApp'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import LabeledInfoField from './LabeledInfoField'
import { getDate } from '../../../utils/methods'
import get from 'lodash/get'
import LabeledCurrency from './LabeledCurrency'
import LabelsSwitch from './LabelsSwitch'


const DataContext = createContext({})

const ReadOnlyForm = (props) => {
  const { label, data } = props
  const formRef = useRef(null)
  const externalData =  useMemo(() => ({ data }),[data])

  return (
    <DataContext.Provider value={externalData}>
      {label && <TypographyApp variant={'h3'} str={label} marginBottom={3}/>}
      <Box ref={formRef}>
        {props.children}
      </Box>
    </DataContext.Provider>
  )
}

const FormComponentWrapper = (props) => {
  const { Component, ...other } = props
  return (
    <Box
      sx={{ display: 'flex', flexWrap: 'wrap' }}
      style={{ marginBottom: '2rem' }}>
      <Grid container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        direction="row"
        justifyContent="flex-start"
        alignItems="center">
        <Component {...other}/>
      </Grid>
    </Box>
  )
}
const CommonButtonWrapper = (props) => {
  const { Component, ...other }= props
  return(
    <Component {...other} />
  )
}

const ReadOnlyInput = (props) => {
  const { id, label } = props
  const { t } = useTranslation()
  const { data } = useContext(DataContext)
  return (<LabeledInfoField value={get(data, id)} label={t(label)} />)
}

const ReadOnlySelect = (props) => {
  const { id, label } = props
  const { t } = useTranslation()
  const { data } = useContext(DataContext)

  return (<LabeledInfoField value={t(`select.${id}.${get(data, id)}`)} label={t(label)} />)
}

const ReadOnlyDate = (props) => {
  const { id, label } = props
  const { t } = useTranslation()
  const { data } = useContext(DataContext)

  return (<LabeledInfoField value={getDate(get(data, id))} label={t(label)} />)
}

const ReadOnlyCurrency = (props) => {
  const { id, label, positive = true } = props
  const { t } = useTranslation()
  const { data } = useContext(DataContext)
  return (<LabeledCurrency value={positive ? get(data, id) : -1* get(data, id)} label={t(label)} fontSize = '1rem' id={id}  textAlign={'end'}/>)
}

const ReadOnlySwitch = (props) => {
  const { id, label } = props
  const { t } = useTranslation()
  const { data } = useContext(DataContext)
  return (<LabelsSwitch value={get(data, id)} label={t(label)} fontSize = '1rem' id={id}  textAlign={'end'}/>)
}
const CommonButton = (props) => {
  const { t } = useTranslation()
  const { label, onClick } = props
  return (
    <Button variant={'main'} size={'large'} onClick={onClick} key={label} disabled={props.disabled}>
      {t(label)}
    </Button>
  )
}

const Input = (props) => <FormComponentWrapper {...props} Component={ReadOnlyInput} />
const Select = (props) => <FormComponentWrapper {...props} Component={ReadOnlySelect} />
const Date = (props) => <FormComponentWrapper {...props} Component={ReadOnlyDate} />
const Currency = (props) => <FormComponentWrapper {...props} Component={ReadOnlyCurrency} />
const Check = (props) => <FormComponentWrapper {...props} Component={ReadOnlySwitch} />
const FormButton = (props) => <CommonButtonWrapper {...props} Component={CommonButton}/>

ReadOnlyForm.Input = Input
ReadOnlyForm.Select = Select
ReadOnlyForm.Currency = Currency
ReadOnlyForm.Check = Check
ReadOnlyForm.Date = Date
ReadOnlyForm.FormButton = FormButton

export default ReadOnlyForm