import React from 'react'
import Box from '@mui/material/Box'

const PersonCard = ({ children }) => {

  return (
    <>
      <Box
        justifyContent="start"
        alignItems="start"
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          '& > :not(style)': {
            m: 1,
          },
        }}

      >
        {children}
      </Box>
    </>
  )
}

export default PersonCard