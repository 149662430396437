import React from 'react'
import Forms from '../../components/dataForm/forms/Forms'
import { DATA_FROMS } from '../../utils/Enums'
import { clearLocalStorageByKey, setLocalStorage } from '../../utils/methods'
import { login } from '../../services/api/user'
import { PATHS } from '../../utils/contants'
import { useRouter } from '../../routes/useRoutes'
import { useAppSetting } from '../../components/providers/AppSettingProvider'
import ContainerWrapper from '../../components/wrappers/ContainerWrapper'

const LoginPage = () => {
  const router = useRouter()
  const { setErrors } = useAppSetting()

  const handleSubmit = async (data) => {
    clearLocalStorageByKey('USER')
    try {
      const user = await login(data)
      if (user && user.token) {
        setLocalStorage('USER', user)
        router.push(PATHS.logged.home)
        window.location.reload()
      }
    } catch (error) {
      setErrors({ message: error.response.data.error })
      setTimeout(() => {
        setErrors({})
      }, 5000)
    }

  }
  return (
    <ContainerWrapper>
      <Forms type={DATA_FROMS.LOGIN} submit={handleSubmit} openNow={true}/>
    </ContainerWrapper>

  )
}

export default LoginPage