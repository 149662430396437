import React from 'react'
import statementSchema from '../schemas/statementSchema'
import { getCurrentDate } from '../../../utils/methods'
import FormikDataForm from '../FormikDataForm'


const intiDalance = {
  fee: '',
  statementType: '',
  description: '',
  month: getCurrentDate().month,
  year: getCurrentDate().year,
}
const StatementForm = ({ close, onSubmit }) => {
  const handleSubmit = (data) => {
    onSubmit(data)
  }
  console.log(intiDalance)
  return (
    <FormikDataForm
      initData={intiDalance}
      label={'statement.form.header'}
      submitSchema={statementSchema}
      handleSubmit={handleSubmit}>
      <FormikDataForm.Input label={'statement.form.fee'} fieldName={'fee'}/>
      <FormikDataForm.Select label={'statement.form.statementType'} fieldName={'statementType'}/>
      <FormikDataForm.Input label={'statement.form.description'} fieldName={'description'}/>
      <FormikDataForm.Select label={'statement.form.month'} fieldName={'month'}/>
      <FormikDataForm.Select label={'statement.form.year'} fieldName={'year'} showOnlyValue={true}/>
      <FormikDataForm.SubmitButton label={'statement.form.save'}  />
      <FormikDataForm.FormButton label={'commons.close'} onClick={() => close()} />
    </FormikDataForm>
  )
}

export default StatementForm