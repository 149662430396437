

const person = 'api/persons'
const transaction = 'api/transactions'
const statement = 'api/statements'
const balance = '/api/balance'
const expense = '/api/expense'
const user = '/api/users'
const token = '/api/token/isValid'
const print = '/api/pdf'

const unAuthApi = '/api/unauthenticated/'

export const urls = {
  unAuth: {
    persons : unAuthApi + 'allpersons',
    lastBalance: unAuthApi + 'lastBalance',
    expenses: unAuthApi + 'expenses',
    donations: unAuthApi + 'donation',
    members: unAuthApi + 'members',
  },
  users: {
    base: user,
    token: token,
  },
  persons: {
    base: person,
  },
  transactions: {
    base: transaction,
    check: transaction+'/checkAll',
    deposits: transaction+'/deposits',
  },
  statements: {
    base: statement,
  },
  balance: {
    base: balance,
    statementBalance: balance + '/statementBalance/',
    balanceHistory: balance + '/balanceHistory',
    balanceTransactions: balance + '/statementBalance/transations/',
    balanceAllTypes: balance + '/organisationBalanceHistory/',
    lastBalance: balance + '/lastOrgBalance',
    expensesBalance: balance + '/expensesBalance',
  },
  expense: {
    base: expense
  },
  prints : {
    transactions: print + '/transactions'
  }
}