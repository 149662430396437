import React from 'react'
import TransactionsTable from './TransactionsTable'
import CustomTypography from '../../components/commons/customs/CustomTypography'

const TransactionsView = () => {
  return (
    <>
      <CustomTypography text={'dashBoard.right.transactions'} variant={'h4'}/>
      <TransactionsTable/>
    </>
  )
}

export default TransactionsView