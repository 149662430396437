import React, { useEffect } from 'react'
import { DATA_FROMS } from '../../../utils/Enums'
import StatementForm from './StatementForm'
import { useMessageContext } from '../../providers/MessageProvider'
import CustomButton from '../../commons/customs/CustomButton'
import ExpenseForm from './ExpenseForm'
import UserForm from './UserForm'
import LoginForm from './LoginForm'

const Forms = ({ submit, type, data, resetData, openNow = false }) => {
  const { formType, setFormType } = useMessageContext()
  useEffect(() => {
    openNow && setFormType(type)
  })
  const handleClose = () => {
    setFormType(DATA_FROMS.CLOSED)
    resetData()
  }

  const forms = {
    [DATA_FROMS.STATEMENT]: <StatementForm onSubmit={submit} close={() => setFormType(DATA_FROMS.CLOSED)}/>,
    [DATA_FROMS.EXPENSE]: <ExpenseForm onSubmit={submit} close={() => setFormType(DATA_FROMS.CLOSED)}/>,
    [DATA_FROMS.USER]: <UserForm onSubmit={submit} close={handleClose} user={data}/>,
    [DATA_FROMS.LOGIN]: <LoginForm onSubmit={submit} close={handleClose} />,
    [DATA_FROMS.CLOSED]: <></>,
  }

  return (
    <>
      {formType === DATA_FROMS.CLOSED && openNow === false && <CustomButton text={`dataform.${type}`} handleClick={() => setFormType(type)} key={type}/>}
      {forms[formType]}
    </>

  )
}

export default Forms




