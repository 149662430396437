import * as Yup from 'yup'
import { TRANSACTION_TYPE } from '../../../utils/Enums'

const transactionSchema = Yup.object({
  amount: Yup.number()
    .typeError('errors.transaction.amount.typeError')
    .required('errors.transaction.amount.required')
    .positive('errors.transaction.amount.positive')
    .integer('errors.transaction.amount.integer')
    .min(5, 'errors.transaction.amount.tooSmall')
    .max(10000, 'errors.transaction.amount.tooLarge'),
  type:Yup.string()
    .required('errors.transaction.type.required')
    .test('type', 'errors.transaction.type.wrong', (value) => {
      return [TRANSACTION_TYPE.BANK, TRANSACTION_TYPE.CASH].includes(value)
    }),
  description: Yup.string()
    .required('errors.transaction.description.required')
})

export default transactionSchema