import React, { useEffect, useState } from 'react'
import CustomTypography from '../../../components/commons/customs/CustomTypography'
import { getCurrentDate } from '../../../utils/methods'
import { UnAuthAPI } from '../../../services/api/Persons'
import List from '@mui/material/List'
import StyledNumber from '../../../components/commons/customs/StyledNumber'
import CustomSelect from '../../../components/commons/customs/CustomSelect'
import { Pagination, Stack, styled } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'

const formatRowCells = (transactions) => ({
  ['id']: transactions?.id,
  ['fullName']: `${transactions?.person?.firstname} ${transactions?.person?.surname}`,
  ['amount']: <StyledNumber key={transactions?.id} number={transactions?.amount} />
})
const Index = () => {
  const [donatationsDate, setDonatationsDate] = useState({
    month: getCurrentDate().month,
    year: getCurrentDate().year
  })
  const [transactions, setTransactions] = useState({})
  const [formatted, setFormatted] = useState([])
  const [page, setPage] = useState(0)
  const StyledListItem = styled(ListItem)`
        text-align: right;
    `

  const getDonations = async (pageParameter) => {
    try {
      const donations = await UnAuthAPI.getDonations(donatationsDate.month, donatationsDate.year, pageParameter, 10)
      setTransactions(donations)
      setPage(donations.page)
      const formatted = donations?.results.map(transaction => formatRowCells(transaction))
      setFormatted(formatted)

    } catch (e) {
      console.log(e)
    }
  }

  const handelPagination = async (e) => {
    if (e?.target?.textContent  && page !== e?.target?.textContent - 1) {
      getDonations(e?.target?.textContent - 1).catch(
        err => console.error(err.message)
      )
    }
  }
  useEffect(() => {
    getDonations(page)
      .catch(err => console.error(err.message))
    // eslint-disable-next-line
    }, [page, donatationsDate])
  const handleDonationsDate= (data) => {
    setDonatationsDate(prevPrintDate => {
      return {
        ...prevPrintDate,
        [data.target.name]: data.target.value
      }
    })

  }
  return (
    <div>
      <CustomTypography text={'publicText.donations'} type={'1'} variant={'h4'}/>
      <Stack direction="column"
        justifyContent="flex-start"
        alignItems="start"
        spacing={2}>
        <div key={'month'}>
          <CustomSelect fieldName={'month'} label={'commons.month'}  defaultValue={donatationsDate.month} handleChange={handleDonationsDate}/>
        </div>
        <div key={'year'}>
          <CustomSelect fieldName={'year'} label={'commons.year'}  defaultValue={donatationsDate.year} handleChange={handleDonationsDate}/>
        </div>
      </Stack>

      <List key={'list'}>
        {formatted && formatted?.map((trs, i) => {
          return (
            <>
              <StyledListItem  key={i + 'item'}>
                <Stack
                  key={i + 'Stack'}
                  sx={{ marginTop: '30px' }}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <div key={i + 'div1'}><CustomTypography text={trs.fullName} type={'5'}/></div>

                  <div key={i + 'div2'}>{trs.amount}</div>
                </Stack>
              </StyledListItem>
              <Divider sx={{ maxWidth: '300px' }} key={i + 'divider'}/>
            </>
          )
        })}

      </List>
      <Pagination
        hideNextButton={true}
        hidePrevButton={true}
        count={transactions?.totalPages}
        variant="outlined"
        color="primary" dir={'ltr'}
        page={page +1}
        onChange={handelPagination} />
    </div>
  )
}

export default Index