import React from 'react'
import Box from '@mui/material/Box'
import { addPerson, rightDrawerObject } from '../../../utils/contants'
import { styled } from '@mui/material'
import { grey } from '../../../theme/palette'
import IconButton from './IconButton'

const PrivateHome = () => {
  const items = [...addPerson, ...rightDrawerObject]

  const StyledBox = styled(Box)`
    color: ${grey['0']};
  `
  return (
    <div>
      <StyledBox
        justifyContent="center"
        alignItems="center"
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          '& > :not(style)': {
            m: 1,
          },
        }}

      >
        {
          items?.map(pageItem => {
            return <IconButton item={pageItem} key={pageItem.id}/>
          })
        }
      </StyledBox>
    </div>

  )
}

export default PrivateHome