import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import { styled } from '@mui/material'
import { useAppSetting } from '../providers/AppSettingProvider'

const  OwnAppBar = () => {
  const { windowSize } = useAppSetting()

  const CustomizedSlider = styled(AppBar)`
    background: transparent;
    height: ${windowSize?.isDownMd ? '50px' : '100px'};
    align-content: flex-end;
  `
  return (
    <CustomizedSlider position="static" elevation={0} open={true}>

    </CustomizedSlider>
  )
}

export default OwnAppBar