// DataContext.js
import React, { createContext, useContext, useEffect, useState } from 'react'
import i18n from '../../i18n/i18n'
import { useMediaQuery, useTheme } from '@mui/material'

const AppSettingContext = createContext(null)
const initialData = {
  mode: 'light',
  language: 'ar',
  direction: 'rtl',
  drawer: true,
}

export const useAppSetting = () => {
  return useContext(AppSettingContext)
}

export const AppSettingProvider = ({ children }) => {
  const theme = useTheme()
  const [data, setData] = useState(initialData)
  const [windowSize, setWindowSize] = useState({})
  const [errors, setErrors] = useState({})

  const isDownXs = useMediaQuery(theme.breakpoints.down('xs'))
  const isUpXs =useMediaQuery(theme.breakpoints.up('xs'))
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isUPSm = useMediaQuery(theme.breakpoints.up('sm'))
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const isDownLg = useMediaQuery(theme.breakpoints.down('lg'))
  const isUpLg = useMediaQuery(theme.breakpoints.up('lg'))
  const wnDownXl = useMediaQuery(theme.breakpoints.down('xl'))
  const isUpXl = useMediaQuery(theme.breakpoints.up('xl'))

  useEffect(() => {
    setWindowSize({
      isDownXs,
      isUpXs,
      isDownSm,
      isUPSm,
      isDownMd,
      isUpMd,
      isDownLg,
      isUpLg,
      wnDownXl,
      isUpXl
    })
  }, [
    isDownXs,
    isUpXs,
    isDownSm,
    isUPSm,
    isDownMd,
    isUpMd,
    isDownLg,
    isUpLg,
    wnDownXl,
    isUpXl,
  ])

  const changeDirectionAndLanguage = () => {
    setData({
      ...data,
      language: data.language === 'ar' ? 'fi' : 'ar',
      direction: data.direction === 'rtl' ? 'ltr' : 'rtl',
    })
    i18n.changeLanguage(data.language === 'ar' ? 'fi' : 'ar').then(r => console.log(r))
  }

  const changeAppMode = () =>
    setData({
      ...data, mode: data.mode === 'light' ? 'dark' : 'light'
    })


  const openDrawer = (value) => {
    setData({
      ...data, drawer: data.drawer = value
    })
  }

  const value = {
    data,
    changeDirectionAndLanguage,
    changeAppMode,
    openDrawer,
    windowSize,
    errors,
    setErrors
  }

  return (
    <AppSettingContext.Provider value={value}>
      {children}
    </AppSettingContext.Provider>
  )
}
