import * as React from 'react'
import Drawer from '@mui/material/Drawer'
import { useAppSetting } from '../providers/AppSettingProvider'
import { Stack } from '@mui/material'
import { home, rightDrawerObject, unauthenticatedDrawerObject } from '../../utils/contants'
import DrawerItem from './DrawerItem'
import { useStorageContext } from '../providers/StorageProvider'

export default function BottomDrawer() {
  const { data, windowSize } = useAppSetting()
  const { authenticatedUser } = useStorageContext()

  const list = () => (
    <Stack
      direction="row"
      spacing={1}
      sx={{ padding: '5px' }}
      justifyContent="center"
      alignItems="center">
      {(authenticatedUser ?
        [...home, ...rightDrawerObject] :
        [...unauthenticatedDrawerObject])?.map(page => (
        page?.showInDrawer && <DrawerItem page={page} windowSize={windowSize} key={page.id + 'drawer'}/>
      ))}
    </Stack>
  )
  return (
    <Drawer
      anchor={windowSize.isDownMd ? 'bottom' : 'top'}
      open={data?.drawer}
      variant={'permanent'}
      sx={{ flexShrink: 0 }}>
      {list('right')}
    </Drawer>
  )
}