import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Stack } from '@mui/material'
import { deletePerson, getOne } from '../../services/api/Persons'
import { useParams } from 'react-router-dom'
import BackButton from '../../components/commons/BackButton'
import ButtonGroupWithGrid from '../../components/commons/grid/ButtonGroupWithGrid'
import { getDate } from '../../utils/methods'
import { useTranslation } from 'react-i18next'
import CustomButton from '../../components/commons/customs/CustomButton'
import { PATHS } from '../../utils/contants'
import { useRouter } from '../../routes/useRoutes'
import { createAccount } from '../../services/api/accounts'
import Account from './accounts/Account'
import CustomTypography from '../../components/commons/customs/CustomTypography'
import ReadOnlyForm from '../../components/StyledComponents/readableOnly/ReadOnlyForm'
import { tableFormatRowCell } from '../../data/rows_data'
import { TABLE_TYPE } from '../../utils/Enums'
import { useMessageContext } from '../../components/providers/MessageProvider'

const PersonInfoPage = () => {
  const { t } = useTranslation()
  const { setCommonModalData, setInfoModal } = useMessageContext()
  const router = useRouter()
  const { id } = useParams()
  const [formattedPerson, setFormattedPerson] = useState({})
  const [personData, setPersonData] = useState()

  const fetchData = async () => {
    try {
      return await getOne(id)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect( () => {
    setInfoModal({})
    fetchData().then(data => {
      setPersonData(data)
      setFormattedPerson(tableFormatRowCell[TABLE_TYPE.PERSON](data, t))
    })
    // eslint-disable-next-line
  }, [id])

  useLayoutEffect(() => {
    return() => {
      setPersonData({})
    }

  }, [])

  const formatRowCell = (id) => {
    const rowFormat = {
      ['fullName']: `${personData?.firstname || ''} ${personData?.surname || ''}`,
      ['birth']: `${personData?.birthCity || ''} ${getDate(personData?.dateOfBirth, 'year') || ''}`,
      ['status']:  t(`person.form.${personData?.gender+personData?.status}`),
      ['familyCount']: personData?.familyCount,
      ['birthCity']: personData?.birthCity,
      ['serialnumber']: personData?.serialnumber,
      ['account']: personData?.account,
    }
    return rowFormat[id]

  }

  const handlePersonDeleting = async () => {
    setCommonModalData({ open: false })
    try {
      await deletePerson(personData?.id)
      router.pushWithData(PATHS.logged.persons)
    } catch (e) {
      if (e?.response?.data?.error) {
        setInfoModal({
          title: `${'service.'+ e?.response?.data?.error}`,
        })
      } else {
        console.error(e)
      }
    }
  }

  const handleDelete = () => {
    setCommonModalData({
      title: 'person.modal.title',
      content: 'person.modal.content',
      arg1: formatRowCell('fullName'),
      open: true,
      buttons: [
        <CustomButton
          text={'person.modal.submit'}
          handleClick={() => handlePersonDeleting()}
          key={'personDeleting'} />,
      ]
    })
  }

  const handlePersonEditing = () => router.pushWithData(PATHS.logged.addPerson, { person: personData })

  const handleAccountAdding = async () => {
    await createAccount({ personId: personData?.id }).then(() => {
      fetchData().then(data => setPersonData(data))
    }).catch(e => console.error(e))
  }

  return (
    <div>
      <Stack sx={{ marginBottom: 10 }}>
        <CustomTypography text={'person.header'} variant={'h4'}/>
        <ReadOnlyForm data={formattedPerson}>
          <ReadOnlyForm.Input id={'fullName'} label={'person.form.firstname'} />
          <ReadOnlyForm.Input id={'birth'} label={'person.form.birth'} />
          <ReadOnlyForm.Input id={'status'} label={'person.form.status'} />
          <ReadOnlyForm.Input id={'familyCount'} label={'person.form.familyCount'} />
          <ReadOnlyForm.Input id={'phone'} label={'person.form.phone'} />
          <ReadOnlyForm.Input id={'serialnumber'} label={'person.form.serialnumber'} />
        </ReadOnlyForm>
        <ButtonGroupWithGrid>
          <BackButton />
          <CustomButton text={'person.form.edit'} handleClick={handlePersonEditing} />
          {
            formatRowCell('account') === undefined && < CustomButton text={'person.form.remove'} handleClick={() => handleDelete()} />
          }

          {
            formatRowCell('account') === undefined && <CustomButton text={'person.accounts.button'} handleClick={() => handleAccountAdding()} />
          }
        </ButtonGroupWithGrid>
        {
          formatRowCell('account') && <Account accountData={formatRowCell('account')} />
        }
      </Stack>
    </div>
  )
}

export default PersonInfoPage