import React from 'react'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

const CustomButton = ({ text, handleClick, disable }) => {
  const { t } = useTranslation()
  return (
    <Button
      variant={'usedColor'}
      size={'large'}
      onClick={handleClick}
      key={text}
      disabled={disable}>
      {t(text)}
    </Button>
  )
}

export default CustomButton