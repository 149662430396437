import React from 'react'
import { ListItemText, styled } from '@mui/material'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'

const CustomListItem = ({ primary = '', secondary = [] }) => {

  const StyledListItem = styled(ListItem)`
        text-align: right;
    `
  return (
    <>
      <StyledListItem key={'StyledListItem'+ Math.random().toString()}>
        <ListItemText
          key={'ListItemText'+ Math.random().toString()}
          primary={primary}
          secondary={secondary?.map(s => s)}
        />
      </StyledListItem>
      <Divider variant="inset" component="li" key={'Divider'+ Math.random().toString()}/>
    </>
  )
}

export default CustomListItem