import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { transaction } from '../../services/api/transactions'
import CustomButton from '../../components/commons/customs/CustomButton'
import { useMessageContext } from '../../components/providers/MessageProvider'
import CommonTable from '../../components/commons/CommonTable'
import { useRouter } from '../../routes/useRoutes'
import { tableColumns } from '../../data/data'
import { TABLE_TYPE } from '../../utils/Enums'
import { tableFormatRowCell } from '../../data/rows_data'

const TransactionsTable = () => {
  const { t } = useTranslation()
  const [formatted, setFormatted] = useState([])
  const { setInfoModal } = useMessageContext()
  const [transactions, setTransactions] = useState({})
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const route = useRouter()

  const fetchTransactionsData = async (pageParameter, limitParameter) => {
    try {
      const data =  await transaction.getAllDepositsTransactions(pageParameter, limitParameter)
      setTransactions(data)
      const formatted = data?.results.map(s => tableFormatRowCell[TABLE_TYPE.TRANSACTION](s, t))
      setFormatted(formatted)
      setPage(data.page)
    } catch (e) {
      console.error(e)
    }
  }
  useEffect(() => {
    fetchTransactionsData(page, rowsPerPage).
      catch(err => console.error(err.message))
    // eslint-disable-next-line
  }, [ page, rowsPerPage])

  const handleChangePage = (event, newPage) => {
    fetchTransactionsData(newPage, rowsPerPage).catch(err => console.error(err.message))
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    fetchTransactionsData(page, event.target.value).catch(err => console.error(err.message))
    setPage(0)
  }

  const handleChosenItem = (transaction) => {
    setInfoModal({
      title: '',
      buttons: [
        <CustomButton
          text={'transaction.modal.toperson'}
          handleClick={() => route.push(`/logged/persons/${transaction.personId}`)} key={'toperson'} />,
      ]
    })
  }


  return (
    <CommonTable
      columns={tableColumns[TABLE_TYPE.TRANSACTION]}
      page={page}
      rowsPerPage={rowsPerPage}
      rows={[...formatted]}
      totalRows={transactions.total || 0}
      onRowClick={handleChosenItem}
      onPageChange={handleChangePage}
      onRowPerPage={handleChangeRowsPerPage}
    />)
}

export default TransactionsTable