import React from 'react'
import { styled, Typography } from '@mui/material'
import { customDay } from '../../../theme/palette'

const StyledNumber = ({ number = 0, fontSize = '1.3rem', type = 'number',textAlign = 'end' }) => {

  const SuccessTypo = styled(Typography)`
    color: ${customDay.success};
    font-size: ${fontSize};
    text-align: ${textAlign};
    `

  const ErrorTypo = styled(Typography)`
    color: ${customDay.error};
    font-size: ${fontSize};
    text-align: ${textAlign};
    `

  const WarningTypo = styled(Typography)`
    color: ${customDay.warning};
    font-size: ${fontSize};
    text-align: ${textAlign};
    `

  const TextTypo = styled(Typography)`
    color: ${customDay.background1};
    font-size: ${fontSize};
    text-align: ${textAlign};
    `

  const typoObject = {
    success: <SuccessTypo dir={'ltr'} key={'SuccessTypo' + number}>{number + ' €'}</SuccessTypo>,
    error: <ErrorTypo dir={'ltr'} key={'ErrorTypo' + number}>{number + ' €'}</ErrorTypo>,
    warning: <WarningTypo dir={'ltr'} key={'WarningTypo' + number}>{number + ' €'}</WarningTypo>,
    text: <TextTypo dir={'ltr'} key={'TextTypo' + number}>{number + ' €'}</TextTypo>,
  }

  const showNumberAs = () => {
    if(type === 'number') {
      return typoObject[number === 0 ? 'warning' : (number > 0 ? 'success' : 'error')]
    } else {
      return typoObject[type]
    }
  }
  return (
    <>
      {showNumberAs()}
    </>
  )
}

export default StyledNumber