import React from 'react'
import transactionSchema from '../schemas/transactionSchema'
import { transaction } from '../../../services/api/transactions'
import { useStorageContext } from '../../providers/StorageProvider'
import FormikDataForm from '../FormikDataForm'

const intiBalance = {
  amount: '',
  type: '',
  description: ''
}

const TransactionForm = ({ account, close }) => {
  const { setUpdateTriggerData } = useStorageContext()
  const handleSubmit = async (data) => {
    try {
      await transaction.createTransaction({ ...data, personId: account.person })
      setUpdateTriggerData()
      close()
    } catch (e) {
      console.error(e.message())
    }
  }
  return (
    <FormikDataForm
      initData={intiBalance}
      label={'transaction.form.header'}
      schema={transactionSchema}
      submitSchema={transactionSchema}
      handleSubmit={handleSubmit}>
      <FormikDataForm.Input label={'transaction.form.amount'} fieldName={'amount'} type={'number'}></FormikDataForm.Input>
      <FormikDataForm.Select label={'transaction.form.type'} fieldName={'type'}></FormikDataForm.Select>
      <FormikDataForm.Select label={'transaction.form.description'} fieldName={'description'}></FormikDataForm.Select>
      <FormikDataForm.FormButton label={'commons.close'} onClick={() => close()} />
      <FormikDataForm.SubmitButton label={'commons.save'}  />


    </FormikDataForm>

  )
}

export default TransactionForm