import React from 'react'
import { useLocation } from 'react-router-dom'
import DataFormPerson from '../../components/dataForm/forms/DataFormPerson'

const PersonView = () => {
  const location = useLocation()
  const receivedData = location.state?.person
  return (
    <>
      <DataFormPerson person={receivedData}/>
    </>
  )
}

export default PersonView
