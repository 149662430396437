import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import ButtonGroupWithGrid from '../grid/ButtonGroupWithGrid'
import { useTranslation } from 'react-i18next'
import CustomButton from './CustomButton'
import { usedColor } from '../../../theme/palette'
import { useAppSetting } from '../../providers/AppSettingProvider'
import { isEmpty } from 'lodash'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 600,
  width: '50%',
  minWidth: 400,
  bgcolor: `${usedColor.common['1']}`,
  border: '1px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
}

const ActionModal = ({ appModal, arg1, closing }) => {
  const { t } = useTranslation()
  const { data } = useAppSetting()
  return (
    <Modal
      open={!isEmpty(appModal)}
      onClose={() => closing()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} dir={data.language === 'ar' ? 'rtl' : 'ltr'}>
        <Typography id="modal-modal-title" variant="subtitle1" component="h2">
          {t(appModal.title)}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {t(appModal.content, { arg1 })}
        </Typography>
        <ButtonGroupWithGrid>
          {
            appModal && appModal.buttons?.length > 0 && appModal.buttons.map(button => button)
          }
          <CustomButton text={'commons.close'} handleClick={() => closing()} />
        </ButtonGroupWithGrid>
      </Box>
    </Modal>
  )
}

export default ActionModal
