import React from 'react'
import ReadOnlyForm from '../../components/StyledComponents/readableOnly/ReadOnlyForm'

const BalanceInfo = ({ balance }) => {
  return (
    <>
      <ReadOnlyForm data={balance}>
        <ReadOnlyForm.Currency id={'balance'} label={'balance.info.orgBalance'} />
        <ReadOnlyForm.Currency id={'deposited'} label={'balance.info.deposited'} />
        <ReadOnlyForm.Currency id={'notDeposited'} label={'balance.info.notDeposited'} positive={false}/>
        <ReadOnlyForm.Input id={'user.name'} label={'balance.info.auditor'} />
        <ReadOnlyForm.Date id={'timestamp'} label={'balance.info.timestamp'} />
      </ReadOnlyForm></>
  )
}

export default BalanceInfo