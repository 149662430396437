import React, { useEffect, useState } from 'react'
import { usersApi } from '../../services/api/users'
import { DATA_FROMS } from '../../utils/Enums'
import Forms from '../../components/dataForm/forms/Forms'
import { useMessageContext } from '../../components/providers/MessageProvider'
import FaceIcon from '@mui/icons-material/Face'
import FaceRetouchingOffIcon from '@mui/icons-material/FaceRetouchingOff'
import CustomCard from '../../components/commons/customs/CustomCard'
import PersonCard from './PersonCard'
import { usedColor } from '../../theme/palette'
import { isEmpty } from 'lodash'

const UsersList = () => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage] = React.useState(10)
  const [usersData, setUsersData] = useState({})
  const [selectedUser, setSelectedUser] = useState({})
  const { setFormType } = useMessageContext()


  const fetchUsers = async (pageParameter, limitParameter) => {
    const data =  await usersApi.getAllUsers(pageParameter, limitParameter)
    setUsersData(data)
    setPage(data.page)
  }

  useEffect(() => {
    fetchUsers(page, rowsPerPage).
      catch(err => console.error(err.message))
    // eslint-disable-next-line
  }, [])

  const formatUser = (user) => ({
    icon: user?.isActive ? <FaceIcon sx={{ color: 'inherit', margin: 2, fontSize: 'inherit', }}/> : <FaceRetouchingOffIcon sx={{ color: 'inherit', margin: 2, fontSize: 'inherit', }}/>,
    list: [user?.name, user?.email],
    borderColor: user.isActive ? usedColor.success.dark: usedColor.error.dark
  })

  const handleSubmits = async (data) => {
    try {
      isEmpty(selectedUser) ?
        await usersApi.createUser(data).catch(e => console.error(e.message)):
        await usersApi.updateUser(selectedUser.id, data).catch(e => console.error(e.message))

      await fetchUsers(page, rowsPerPage)
      setFormType(DATA_FROMS.CLOSED)
    } catch (e) {
      console.error(e.message)
    }
  }


  const handleClick = (item) => {
    setSelectedUser(item)
    setFormType(DATA_FROMS.USER)
  }
  return (
    <>
      <PersonCard>
        {usersData.results?.map((user, i) => <CustomCard key={i} props={formatUser(user)} isClicked={() => handleClick(user)}/>) }
      </PersonCard>
      <br />
      {/*<Pagination count={10} variant="outlined" color="primary" dir={'ltr'} onClick={handelPagination}/>*/}
      <Forms type={DATA_FROMS.USER} submit={handleSubmits} data={selectedUser} resetData={() => setSelectedUser({})}/>
    </>
  )
}

export default UsersList