import React from 'react'
import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { usedColor } from '../../theme/palette'

const CommonTable = ({ columns, rows, totalRows, onRowClick, onPageChange, onRowPerPage, page, rowsPerPage }) => {
  const { t } = useTranslation()
  const StyledTableCell = styled(TableCell)({
    paddingTop: '0.2rem',
    paddingBottom: '0.5rem',
    textAlign: 'start',
  })
  const StyledTableRow = styled(TableRow)`
      // eslint-disable-next-line
  &.MuiTableRow-hover:hover {
      background-color:  ${usedColor.common['1']};
  }
    `

  const StyledTableCellHead = styled(TableCell)({
    textAlign: 'start',
    color: usedColor.common['1'],
    borderBottom: `2px solid ${usedColor.primary.main}`,
    backgroundColor: usedColor.primary.main,
  })

  const style =
      {
        width: '100%',
        overflow: 'hidden',
        marginTop: '4rem ',
        marginBottom: '2rem ',
        border: `2px solid ${usedColor.primary.main}`,
        borderRadius: '5px',
      }
  return (
    <Paper sx={style} elevation={0}>
      <TableContainer sx={{ maxHeight: 1000 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCellHead key={column.id} style={{ width: column.width, minWidth: column.minWidth }}>
                  {t(column.label)}
                </StyledTableCellHead>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map( r => {
              return (
                <StyledTableRow
                  hover
                  tabIndex={-1}
                  key={r.id}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => onRowClick(r)}>
                  {columns.map((column) => {
                    return (<StyledTableCell key={column.id}>
                      {r[column.id]}</StyledTableCell>
                    )
                  })}
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage={t('commons.tableRow')}
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowPerPage}
      />
    </Paper>
  )
}

export default CommonTable