import React from 'react'
import { Typography } from '@mui/material'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import { usedColor } from '../../../theme/palette'
import { useTranslation } from 'react-i18next'
import CustomButton from './CustomButton'
import ButtonGroupWithGrid from '../grid/ButtonGroupWithGrid'
import isEmpty from 'lodash/isEmpty'
import { useAppSetting } from '../../providers/AppSettingProvider'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 600,
  width: '50%',
  minWidth: 400,
  bgcolor: `${usedColor.common['1']}`,
  border: `2px solid ${usedColor.primary.main}`,
  borderRadius: '10px',
  p: 4,
}

const InfoModal = ( { infoModal, closing }) => {
  const { t } = useTranslation()
  const { data } = useAppSetting()

  return (
    <Modal
      open={!isEmpty(infoModal)}
      onClose={() => closing()}
    >
      <Box sx={style} dir={data.language === 'ar' ? 'rtl' : 'ltr'}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: usedColor.primary.main }}>
          {t(infoModal.title)}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {t(infoModal.context)}
        </Typography>
        <ButtonGroupWithGrid>
          {infoModal.buttons && infoModal.buttons.map(button => button)}
          <CustomButton text={t('commons.close')} handleClick={() => closing()} />
        </ButtonGroupWithGrid>
      </Box>
    </Modal>
  )
}

export default InfoModal