import React from 'react'
import CustomTypography from '../../components/commons/customs/CustomTypography'
import PersonsTable from './PersonsTable'

const PersonsView = () => {
  return (
    <>
      <CustomTypography text={'dashBoard.persons'} variant={'h4'}/>
      <PersonsTable />
    </>
  )
}

export default PersonsView