import React, { useEffect } from 'react'
import { clearAllLocalStorage } from '../../utils/methods'
import { useRouter } from '../../routes/useRoutes'

const Index = ({ func }) => {
  const router = useRouter()
  useEffect(() => {
    clearAllLocalStorage('USER')
    func(null)
    router.push('/')
    // eslint-disable-next-line
  }, [])
  return (
    <div>
            logged out
    </div>
  )
}

export default Index