import React from 'react'
import CustomTypography from '../../components/commons/customs/CustomTypography'
import ExpenseTable from './ExpenseTable'

const ExpenseView = () => {
  return (
    <>
      <CustomTypography text={'dashBoard.right.expense'} variant={'h4'}/>
      <ExpenseTable />
    </>
  )
}

export default ExpenseView