import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMessageContext } from '../../components/providers/MessageProvider'
import CommonTable from '../../components/commons/CommonTable'
import { useRouter } from '../../routes/useRoutes'
import _ from 'lodash'
import { expenses } from '../../services/api/expenses'
import ButtonGroupWithGrid from '../../components/commons/grid/ButtonGroupWithGrid'
import BackButton from '../../components/commons/BackButton'
import { DATA_FROMS, TABLE_TYPE } from '../../utils/Enums'
import Forms from '../../components/dataForm/forms/Forms'
import { PATHS } from '../../utils/contants'
import { tableColumns } from '../../data/data'
import { tableFormatRowCell } from '../../data/rows_data'

const ExpenseTable = () => {
  const { t } = useTranslation()
  const [formatted, setFormatted] = useState([])
  const { setFormType } = useMessageContext()
  const [expensesData, setExpensesData] = useState({})
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const router = useRouter()


  const fetchExpensesData = async (pageParameter, limitParameter) => {
    try {
      const data = await expenses.getAllExpenses(pageParameter, limitParameter)
      setExpensesData(data)
      const formatted = data?.results.map(s => tableFormatRowCell[TABLE_TYPE.EXPENSE](s, t))
      setFormatted(formatted)
      setPage(data.page)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    fetchExpensesData(page, rowsPerPage).then()
    // eslint-disable-next-line
    }, [ page, rowsPerPage])

  const handleChangePage = (event, newPage) => {
    fetchExpensesData(newPage, rowsPerPage).catch(err => console.error(err.message))
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    fetchExpensesData(page, event.target.value).catch(err => console.error(err.message))
    setPage(0)
  }

  const handleOpen = async (expense) => {
    router.push(`${PATHS.logged.expense}/${expense.id}`)
  }

  const createNewExpense =async (data) => {
    try {
      await expenses.createExpense(data)
      await fetchExpensesData(page, rowsPerPage)
      setFormType(DATA_FROMS.CLOSED)
    } catch (e) {
      console.error(e)
    }

  }
  return (
    <div>
      <CommonTable
        columns={tableColumns[TABLE_TYPE.EXPENSE]}
        page={page}
        rowsPerPage={rowsPerPage}
        rows={_.reverse([...formatted])}
        totalRows={expensesData.total || 0}
        onRowClick={handleOpen}
        onPageChange={handleChangePage}
        onRowPerPage={handleChangeRowsPerPage}
      />
      <Forms type={DATA_FROMS.EXPENSE} submit={createNewExpense} />
      <ButtonGroupWithGrid>
        <BackButton />
      </ButtonGroupWithGrid>
    </div>)
}

export default ExpenseTable

