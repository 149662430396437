import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { balances } from '../../services/api/balances'
import CommonTable from '../../components/commons/CommonTable'
import { useRouter } from '../../routes/useRoutes'
import { tableColumns } from '../../data/data'
import { TABLE_TYPE } from '../../utils/Enums'
import { tableFormatRowCell } from '../../data/rows_data'

const OrgBalabceHistoryView = () => {
  const { t } = useTranslation()
  const [orgBalance, setOrgBalance] = useState({})
  const [formatted, setFormatted] = useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const router = useRouter()

  const fetchOrgBalanceHistory = async (pageParameter, limitParameter) => {
    try {
      const data =  await balances.getOrgBalanceHistory(pageParameter, limitParameter)
      setOrgBalance(data)
      const formatted = data?.results.map(s => tableFormatRowCell[TABLE_TYPE.BALANCE](s, t))
      setFormatted(formatted)
      setPage(data.page)
    } catch (e) {
      console.error(e)
    }

  }
  useEffect(() => {
    fetchOrgBalanceHistory(page, rowsPerPage).
      catch(err => console.error(err.message))
    // eslint-disable-next-line
  }, [])

  const handleChosenItem = async (balance) => {
    router.push(`/logged/organisationBalanceHistory/${balance.type}/${balance.id}`)
  }

  const handleChangePage = (event, newPage) => {
    fetchOrgBalanceHistory(newPage, rowsPerPage).catch(err => console.error(err.message))
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    console.log('Page has been changed', event)
    setRowsPerPage(event.target.value)
    fetchOrgBalanceHistory(page, event.target.value).catch(err => console.error(err.message))
    setPage(0)
  }

  return (
    <div>
      <CommonTable
        columns={tableColumns[TABLE_TYPE.BALANCE]}
        page={page}
        rowsPerPage={rowsPerPage}
        rows={formatted}
        totalRows={orgBalance.total || 0}
        onRowClick={handleChosenItem}
        onPageChange={handleChangePage}
        onRowPerPage={handleChangeRowsPerPage}
      />
    </div>
  )
}

export default OrgBalabceHistoryView