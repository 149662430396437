import React from 'react'
import { Button, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ButtonGroupWithGrid = ({ children }) => {
  return (
    <Grid container
      rowSpacing={4}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      style={{ marginTop: '1rem' }}
      direction="row"
      justifyContent="flex-start"
      alignItems="center">
      <div aria-label="large button group">
        {children}
      </div>
    </Grid>
  )
}
export const ButtonGrid = ({ label, onClick, color= 'primary' }) => {
  const { t } = useTranslation()
  return (
    <Button
      style={{ margin: '0.5rem', border: '1px solid', borderRadius: '5px' }}
      variant="outlined"
      size="large"
      color={color}
      onClick={onClick}>
      {t(label)}
    </Button>
  )
}


export default ButtonGroupWithGrid


