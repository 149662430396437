import React, { useEffect, useState } from 'react'
import { createPerson, modifyIPerson } from '../../../services/api/Persons'
import { PATHS } from '../../../utils/contants'
import { useRouter } from '../../../routes/useRoutes'
import { personValidationSchema } from '../schemas/personSchema'
import FormikDataForm from '../FormikDataForm'


const initialFormState = {
  firstname: '',
  surname: '',
  gender: '',
  status: '',
  familyCount: 1,
  dateOfBirth: '',
  birthCountry: '',
  birthCity: '',
  phone: ''
}

const DataFormPerson = ({ person }) => {
  const router = useRouter()
  const [data, setData] = useState(person ? person : initialFormState)
  useEffect(() => {
    person ? setData(person) : setData(initialFormState)
  }, [person])


  const filterData = (dataToUpdate) => {
    delete dataToUpdate.serialnumber
    delete dataToUpdate.createdBy
    delete dataToUpdate.createdDate
    delete dataToUpdate.id
    delete dataToUpdate.phoneConfirmed
    return dataToUpdate
  }

  const handleSubmit = async (toSaveData) => {
    try {
      person ? await modifyIPerson(data.id, filterData(toSaveData)) : await createPerson(toSaveData)
      router.push(PATHS.logged.persons)
    } catch (e) {
      new Error(e.message)
    }
  }

  return (
    <div>
      <FormikDataForm
        initData={data}
        label='person.form.header'
        handleSubmit={handleSubmit}
        submitSchema={personValidationSchema}>
        <FormikDataForm.Input label={'person.form.firstname'} fieldName={'firstname'}/>
        <FormikDataForm.Input label={'person.form.surname'} fieldName={'surname'} />
        <FormikDataForm.Select label={'person.form.familyCount'} fieldName={'familyCount'} />
        <FormikDataForm.Select label={'person.form.gender'} fieldName={'gender'} />
        <FormikDataForm.Select label={'person.form.status'} fieldName={'status'} />
        <FormikDataForm.Input label={'person.form.phone'} fieldName={'phone'} />
        <FormikDataForm.Input label={'person.form.birthCountry'} fieldName={'birthCountry'} />
        <FormikDataForm.Input label={'person.form.birthCity'} fieldName={'birthCity'} />
        <FormikDataForm.DatePicker label={'person.form.dateOfBirth'} fieldName={'dateOfBirth'} />
        <FormikDataForm.BackButton />
        <FormikDataForm.SubmitButton label={'person.form.save'}  />


      </FormikDataForm>
    </div>
  )
}

export default DataFormPerson